import { useEffect, useState } from 'react'
import style from './navbar.module.scss'
import {
  AppBar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailIcon from '@mui/icons-material/Mail'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Invitation } from '../types/Invitation'
import { useLocation, useNavigate } from 'react-router-dom'
import { timeSince } from '../util/date'
import { useSnackbar } from 'notistack'
import { useServices } from '../hooks/services'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Organization } from '../types/Organization'
import { Link } from './link'

export const Navbar: React.FC<any> = (props: {
  title?: string
  onRefresh?: Function
  back?: Function
  backIcon?: any
  org?: string
}) => {
  const { auth, invitation } = useServices()

  const navigate = useNavigate()
  const loc = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const [nAnchorEl, setNAnchorEl] = useState<null | HTMLElement>(null)
  const isNOpen = Boolean(nAnchorEl)

  const handleNotificationMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setNAnchorEl(e.currentTarget)
    setAnchorEl(null)
    setIAnchorEl(null)
  }

  const [iAnchorEl, setIAnchorEl] = useState<null | HTMLElement>(null)
  const isIOpen = Boolean(iAnchorEl)

  const handleInvitationMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setIAnchorEl(e.currentTarget)
    setAnchorEl(null)
    setNAnchorEl(null)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setIAnchorEl(null)
    setNAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setNAnchorEl(null)
    setIAnchorEl(null)
  }

  const nMenuId = 'dashboard-notification-menu'
  const iMenuId = 'dashboard-invitation-menu'
  const menuId = 'dashboard-account-menu'

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ userSelect: 'none', marginTop: '40px' }}
    >
      <MenuItem
        onClick={() => {
          navigate('/profile')
        }}
        sx={{ minWidth: '200px' }}
      >
        Profile
      </MenuItem>
      <MenuItem
        onClick={() => {
          auth.logout()
          handleMenuClose()
          navigate('/login')
        }}
      >
        Log out
      </MenuItem>
    </Menu>
  )

  const [refresh, setRefresh] = useState(false)
  const [invites, setInvites] = useState<Invitation[]>([])

  useEffect(() => {
    let unmounted = false
    invitation
      .getInvitations()
      .then((value) => {
        if (!unmounted) setInvites(value)
      })
      .catch((err) => {})
    return () => {
      unmounted = true
    }
  }, [refresh])

  const renderNotificationMenu = (
    <Menu
      anchorEl={nAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={nMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isNOpen}
      onClose={handleMenuClose}
      style={{ userSelect: 'none', marginTop: '40px' }}
    >
      <p style={{ width: '280px', padding: '10px', textAlign: 'center' }}>No new notifications.</p>
    </Menu>
  )

  const [open, setOpen] = useState<boolean>(false)
  const [invite, setInvite] = useState<null | Invitation>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)

  const renderInvitationMenu = (
    <Menu
      anchorEl={iAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={iMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isIOpen}
      onClose={handleMenuClose}
      style={{ userSelect: 'none', marginTop: '40px' }}
    >
      {invites.length === 0 ? (
        <p style={{ width: '280px', padding: '10px', textAlign: 'center' }}>No new invitations.</p>
      ) : (
        invites.map((val, index) => {
          return (
            <MenuItem key={`invite-${val._id}`} className={style.inviteItem}>
              <p
                onClick={() => {
                  setInvite(val)
                  setOpen(true)
                  handleMenuClose()
                }}
              >
                <CorporateFareIcon />
                {`${val.from.firstname} ${val.from.lastname} invited you to ${(val.organization as Organization).name}`}
              </p>
              <div className={style.notiRow}>
                <p>{timeSince(new Date(val.createdAt))} ago</p>
                <Button
                  variant="text"
                  color="error"
                  onClick={() => {
                    invitation
                      .deleteInvitation(val._id)
                      .then((val) => {
                        setRefresh(!refresh)
                      })
                      .catch((err) => {})
                  }}
                >
                  Dismiss
                </Button>
              </div>
            </MenuItem>
          )
        })
      )}
    </Menu>
  )

  return (
    <>
      <AppBar position="sticky">
        <Toolbar className={style.toolbar}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '300px' }}>
            {props.back ? (
              <IconButton
                size="large"
                aria-label="go back"
                color="inherit"
                onClick={() => {
                  if (props.back) props.back()
                }}
              >
                <Badge badgeContent={0} color="error">
                  {props.backIcon ? props.backIcon() : <ArrowBackIcon />}
                </Badge>
              </IconButton>
            ) : null}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' }, userSelect: 'none', marginLeft: props.back ? '10px' : '0' }}
            >
              {props.title ?? 'Dashboard'}
            </Typography>
          </div>
          <Box sx={{ flexGrow: 1 }} />

          {/* Show organization tabs */}
          {props.org ? (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Link
                name="Overview"
                path={`/organization/${props.org}`}
                active={loc.pathname === `/organization/${props.org}`}
              />
              <Link
                name="Teams"
                path={`/organization/${props.org}/teams`}
                active={loc.pathname === `/organization/${props.org}/teams`}
              />
              <Link
                name="Matches"
                path={`/organization/${props.org}/matches`}
                active={loc.pathname === `/organization/${props.org}/matches`}
              />
              <Link
                name="Tournaments"
                path={`/organization/${props.org}/tournaments`}
                active={loc.pathname === `/organization/${props.org}/tournaments`}
              />
            </div>
          ) : null}

          {/* Show dashboard tabs */}
          {props.title === 'Dashboard' || props.title === 'Billing' ? (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Link name="Dashboard" path={`/dashboard`} active={loc.pathname === `/dashboard`} />
              <Link name="Billing" path={`/billing`} active={loc.pathname === `/billing`} />
            </div>
          ) : null}

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }} style={{ minWidth: '300px', justifyContent: 'flex-end' }}>
            <IconButton
              size="large"
              aria-label={`${invites.length} invitation${invites.length === 1 ? '' : 's'}`}
              color="inherit"
              onClick={handleInvitationMenuOpen}
              aria-haspopup="true"
            >
              <Badge badgeContent={invites.length} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 0 new notifications"
              color="inherit"
              onClick={handleNotificationMenuOpen}
              aria-haspopup="true"
            >
              <Badge badgeContent={0} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Dialog
        onClose={() => {
          setOpen(false)
          setLoading(false)
          setInvite(null)
        }}
        open={open}
      >
        {invite === null ? null : (
          <>
            {loading ? <LinearProgress /> : null}
            <div className={style.orgPreview}>
              <CorporateFareIcon fontSize="large" />
              <div>
                <p>{(invite.organization as Organization).name}</p>
                <p>
                  {(invite.organization as Organization).members.length} member
                  {(invite.organization as Organization).members.length === 1 ? '' : 's'}
                </p>
              </div>
            </div>
            <DialogContent>
              <DialogContentText>
                Would you like to accept the invitation to {(invite.organization as Organization).name} from{' '}
                {invite.from.firstname} {invite.from.lastname}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  invitation
                    .deleteInvitation(invite._id)
                    .then((val) => {
                      setOpen(false)
                      setInvite(null)
                      setLoading(false)
                      setError(false)
                      setRefresh(!refresh)
                      enqueueSnackbar('Invitation deleted!', { variant: 'success' })
                      if (props.onRefresh) props.onRefresh()
                    })
                    .catch((err) => {
                      setLoading(false)
                      setError(true)
                      setRefresh(!refresh)
                      enqueueSnackbar('Backend offline.', { variant: 'error' })
                      if (props.onRefresh) props.onRefresh()
                    })
                }}
                disabled={loading}
              >
                Decline
              </Button>
              <Button
                onClick={() => {
                  invitation
                    .acceptInvitation(invite._id)
                    .then((val) => {
                      setOpen(false)
                      setInvite(null)
                      setLoading(false)
                      setError(false)
                      setRefresh(!refresh)
                      enqueueSnackbar('Joined organization!', { variant: 'success' })
                      if (props.onRefresh) props.onRefresh()
                    })
                    .catch((err) => {
                      setLoading(false)
                      setError(true)
                      setRefresh(!refresh)
                      enqueueSnackbar('Backend offline.', { variant: 'error' })
                      if (props.onRefresh) props.onRefresh()
                    })
                }}
                disabled={loading}
              >
                Accept
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      {renderMenu}
      {renderInvitationMenu}
      {renderNotificationMenu}
    </>
  )
}
