// External Dependencies
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { Routes, Route, useLocation, Navigate, useParams, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

// Icons
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard'
import AccountCircle from '@mui/icons-material/AccountCircle'
import StorageIcon from '@mui/icons-material/Storage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MonitorIcon from '@mui/icons-material/Monitor'

// Components
import { ConnectionGraph } from '../../components/connection-graph'
import { AvatarGroup } from '../../components/avatar-group'
import { ListItem } from '../../components/list-item'

// Pages
import { Dashboard } from './dashboard'
import { NotFound } from '../not-found'
import { Overlay } from './overlay'

// Assets
import sidebarImage from '../../assets/sidebar.jpeg'
import style from './server.module.scss'

// Hooks
import { useLogger } from '../../hooks/logger'
import { useServices } from '../../hooks/services'
import { Loading } from './loading'

import { Server as IServer } from '../../types/Server'
import { useRefresh } from '../../hooks/refresh'
import { ServerContext } from './ServerContext'

export const Server: React.FC<any> = () => {
  const { auth, websocket, platform, organization, server } = useServices()
  const { pathname } = useLocation()
  const { id } = useParams()
  const [info, warn, error] = useLogger()
  const [refresh, setRefresh] = useState(false)
  const [servers, setServers] = useState<IServer[]>([])
  const [connections, setConnections] = useState<any[]>([])
  const [disconnected, setDisconnected] = useState(false)
  const [org, setOrg] = useState<string>('')
  const navigate = useNavigate()

  const basePath = `/server/${id}`

  useRefresh(() => {
    websocket.getAllConnections((cons: any[]) => {
      setConnections(cons)
    })
  }, 1000)

  useRefresh(() => {
    server
      .getServer(id!, false)
      .then((val) => {
        setOrg(val.organization_id)
        server
          .getServers(val.organization_id, false)
          .then((servs) => setServers(servs))
          .catch((err) => {})
      })
      .catch((err) => {})
  }, 10000)

  useEffect(() => {
    const disconHandler = () => {
      setDisconnected(true)
    }

    websocket.io.on('disconnect', disconHandler)

    return () => {
      websocket.io.removeListener('disconnect', disconHandler)
    }
  }, [websocket.loggedIn])

  if (!websocket.loggedIn || websocket.server_id !== id)
    return (
      <Loading
        server={id}
        onLoggedIn={() => {
          navigate(`/server/${id}/dashboard`)
          setRefresh(!refresh)
        }}
      />
    )

  return (
    <ServerContext.Provider value={{ organization: org }}>
      <div className={style.server}>
        {disconnected ? (
          <div className={style.offline}>
            <p>The server you were connected to went offline.</p>
          </div>
        ) : null}
        <div className={style.sidebar}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
              <StorageIcon />
              <p>{servers.find((x) => x._id === id)?.name ?? 'Unnamed Server'}</p>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              {servers.filter((x) => x._id !== id && x.instance_id.startsWith('i-')).length > 0 ? (
                servers.map((val: IServer, index: number) => {
                  if (val._id === id) return null
                  if (!val.instance_id.startsWith('i-')) return null
                  return (
                    <Typography
                      className={style.clickable}
                      key={`serv-${val._id}`}
                      onClick={() => {
                        window.location.href = `/server/${val._id}/dashboard`
                      }}
                    >
                      {val.name ?? 'Unnamed Server'}
                    </Typography>
                  )
                })
              ) : (
                <p style={{ textAlign: 'center', margin: '15px' }}>No other servers found.</p>
              )}
            </AccordionDetails>
          </Accordion>
          <ListItem
            Icon={() => <DeveloperBoardIcon />}
            title={'Dashboard'}
            link={`${basePath}/dashboard`}
            active={pathname === `${basePath}/dashboard` ? true : false}
          />
          <ListItem
            Icon={() => <MonitorIcon />}
            title={'Overlay'}
            link={`${basePath}/overlay`}
            active={pathname === `${basePath}/overlay` ? true : false}
          />
          <img src={sidebarImage} />
          <p style={{ position: 'absolute', bottom: '20px' }}>
            Server Version: {websocket.info ? 'v' + websocket.info.version : 'Unknown'}
          </p>
        </div>
        <div data-disconnected={disconnected ? 'true' : 'false'}>
          {/* Shared navbar [toolset] */}
          <div className={style.navbar}>
            {/* Page Title */}
            <Routes>
              <Route
                path="/login"
                element={
                  <div>
                    <AccountCircle style={{ fontSize: 35 }} />
                    <h2 style={{ marginLeft: 10 }}>Login</h2>
                  </div>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <div>
                    <DeveloperBoardIcon style={{ fontSize: 35 }} />
                    <h2 style={{ marginLeft: 10 }}>Dashboard</h2>
                  </div>
                }
              />
              <Route
                path="/overlay"
                element={
                  <div>
                    <MonitorIcon style={{ fontSize: 35 }} />
                    <h2 style={{ marginLeft: 10 }}>Overlay</h2>
                  </div>
                }
              />
            </Routes>

            <div className={style.navbarRight}>
              <AvatarGroup
                urls={connections.map((val) => {
                  // eventually, we can pass in avatars to control board connections
                  if (val.type === 'CONTROLBOARD') return 'https://www.dropbox.com/s/oi3axn8oqbnjcsj/umn.png?dl=1'
                  return null
                })}
                _ids={connections.map((val) => {
                  if (val.type === 'CONTROLBOARD') return val.email ?? val.id
                  return null
                })}
              />
              <ConnectionGraph width={200} color={'BLACK'} />
              {/*<Avatar
              url="https://www.dropbox.com/s/oi3axn8oqbnjcsj/umn.png?dl=1"
              size="MEDIUM"
              _id="TEST_ID"
              onClick={(e: React.MouseEvent) => console.log('avatar clicked', e)}
            />*/}
            </div>
          </div>

          {/* Router */}
          <div className={style.router}>
            <Routes>
              {/* server connection page; redirect to /dashboard on success */}
              <Route path="/dashboard" element={<Dashboard websocket={websocket} />} />
              <Route path="/overlay" element={<Overlay websocket={websocket} auth={auth} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>

          {/* Test objects */}
          {/*<Avatar
          url="https://www.dropbox.com/s/oi3axn8oqbnjcsj/umn.png?dl=1"
          size="LARGE"
          _id="TEST_ID"
          onClick={(e: React.MouseEvent) => console.log('avatar clicked', e)}
        />*/}
          {/*<Card
          color="#7a0019"
          Icon={() => <SensorsIcon />}
          title="Connection Status"
          description="Excellent"
          Body={() => <p>Body here</p>}
          expandable={true}
        />*/}
          {/*<TeamCard
          avatar="https://www.dropbox.com/s/oi3axn8oqbnjcsj/umn.png?dl=1"
          name="UMN Maroon"
          color_primary="#7a0019"
          color_secondary="#ffcc33"
          roster={['chez', 'peej', 'rad']}
          _id="TEST_ID"
          onEdit={(_id: string) => console.log('editing', _id)}
          onDelete={(_id: string) => console.log('deleting', _id)}
        />*/}
        </div>
      </div>
    </ServerContext.Provider>
  )
}

export * from './offline'
