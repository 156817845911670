import style from './card.module.scss'
import { Paper, Divider } from '@mui/material'
import React, { CSSProperties, useState } from 'react'

// When using cards in a flex-row, use 'style' to set the max height wherever possible
export const Card: React.FC<any> = (props: {
  color: string
  Icon: React.FC
  title: string
  description: string
  Body?: any
  expandable?: boolean
  style?: CSSProperties
}) => {
  const { Icon, Body } = props
  const [expanded, setExpanded] = useState(false)

  return (
    <Paper
      data-expanded={expanded}
      data-description={props.description}
      data-expandable={props.expandable ?? false}
      elevation={2}
      className={style.card}
      style={props.style}
    >
      <div className={style.cardTop}>
        <Paper className={style.cardIcon} style={{ backgroundColor: props.color }} elevation={4}>
          <Icon />
        </Paper>
        <div className={style.cardTopText}>
          <p>{props.title}</p>
          <p>{props.description}</p>
        </div>
      </div>
      {Body ? (
        <div>
          {props.expandable ? (
            <div>
              <Divider />
              {expanded ? (
                <div>
                  {Body}
                  <Divider />
                </div>
              ) : null}
              <div className={style.cardBottom} onClick={() => setExpanded(!expanded)}>
                {expanded ? <p>Collapse</p> : <p>Expand</p>}
              </div>
            </div>
          ) : (
            Body
          )}
        </div>
      ) : null}
    </Paper>
  )
}
