import { useNavigate } from 'react-router-dom'
import style from './link.module.scss'

export const Link: React.FC<any> = (props: { name: string; path: string; active: boolean }) => {
  const navigate = useNavigate()
  return (
    <p className={style.link} data-active={props.active ? 'true' : 'false'} onClick={() => navigate(props.path)}>
      {props.name}
    </p>
  )
}
