import CorporateFare from '@mui/icons-material/CorporateFare'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import { Organization } from '../types/Organization'
import style from './org-card.module.scss'
import { useNavigate } from 'react-router-dom'

export const OrganizationCard: React.FC<any> = (props: { organization: Organization; onCreate?: Function }) => {
  const { organization, onCreate } = props
  const navigate = useNavigate()

  if (onCreate) {
    return (
      <div className={style.createCard} onClick={() => onCreate()}>
        <AddCircleOutline />
        <p>Create Organization</p>
      </div>
    )
  }

  return (
    <div
      className={style.card}
      onClick={() => {
        navigate(`/organization/${organization._id}`)
      }}
    >
      <div style={{ backgroundColor: organization.color }}></div>
      <div>
        <div>
          <CorporateFare fontSize="large" />
        </div>
        <div>
          <p>{organization.name}</p>
        </div>
      </div>
    </div>
  )
}
