import style from './connection-graph.module.scss'
import React, { useEffect, useState } from 'react'
import ChartistGraph from 'react-chartist'
import Monitor from '@mui/icons-material/Monitor'
import Games from '@mui/icons-material/Games'
import { Tooltip } from '@mui/material'
import { useRefresh } from '../hooks/refresh'
import { useWebsocket } from '../hooks/services'
import Storage from '@mui/icons-material/Storage'

/* 

  ConnectionGraph
    - Eventually, we will pass in the WebsocketService so
      that the ConnectionGraph can get the data it needs
      on its own, given 'PLUGIN' or 'OVERLAY'. Also, modify
      the graph scales based on what the current rate is so
      that it scales accordingly

*/
export const ConnectionGraph: React.FC<any> = (props: {
  width: number
  color: 'BLUE' | 'PINK' | 'YELLOW' | 'GREEN' | 'BLACK'
}) => {
  var opts = {
    high: 20,
    low: 0,
    stretch: true,
    ticks: [25, 50, 75, 100],
    width: props.width,
    height: 40,
    axisX: {
      showLabel: false,
      offset: 0,
      showGrid: false,
    },
    axisY: {
      showLabel: false,
      offset: 0,
      showGrid: true,
    },
    showPoint: false,
    fullWidth: true,
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  }

  const websocket = useWebsocket()
  const [connections, setConnections] = useState<any[]>([])
  const [data, setData] = useState<any[]>([])
  let rate = 0

  useRefresh(() => {
    // Update data
    const newData = data
    if (newData.length > 50) newData.shift()

    newData.push(rate)
    rate = 0
    setData(newData)

    websocket.getAllConnections((cons: any[]) => {
      setConnections(cons)
    })
  }, 1000)

  useEffect(() => {
    const handler = () => {
      rate++
    }

    websocket.io.on('heartbeat', handler)

    return () => {
      websocket.io.removeListener('heartbeat', handler)
    }
  }, [true])

  return (
    <Tooltip title={'Server Health'} arrow>
      <div className={style.graph} data-color={props.color}>
        <Storage />
        <ChartistGraph data={{ series: [data, new Array(50).fill(-10)] }} options={opts} type={'Line'} />
        <div style={{ marginLeft: -props.width, width: props.width }}>
          <p>Connections: {connections.length}</p>
          <p>Heartbeat: {data[data.length - 1] ?? 0} bps</p>
        </div>
      </div>
    </Tooltip>
  )
}
