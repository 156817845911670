import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { createContext, StrictMode } from 'react'
import { render } from 'react-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// Project deps
import { Dashboard, Home, Login, NotFound, Server, Organization, Billing, OfflineServer } from './pages'
import reportWebVitals from './reportWebVitals'
import './index.scss'
import {
  AuthService,
  InvitationService,
  OrganizationService,
  PlatformService,
  ServerService,
  ServiceContext,
  StripeService,
  TeamService,
  WebsocketService,
} from './services'
import { createTheme, ThemeProvider } from '@mui/material'

const stripePromise = loadStripe(
  'pk_test_51L0FKPEHzSiGPRsUSeJqTLCI2AE7Y0hzONBUI4ZJtzsFH756LFQZALOBKjUCpcXLLH71QERoMGdjXt4hCjn0Wj2t00ETScGu7I',
)

const auth: AuthService = new AuthService()
const websocket: WebsocketService = new WebsocketService(auth)
const platform: PlatformService = new PlatformService(auth)
const invitation: InvitationService = new InvitationService(auth)
const organization: OrganizationService = new OrganizationService(auth)
const server: ServerService = new ServerService(auth)
const team: TeamService = new TeamService(auth)
const stripe: StripeService = new StripeService(auth)

const services = {
  auth,
  websocket,
  platform,
  invitation,
  organization,
  server,
  team,
  stripe,
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#324044',
    },
    secondary: {
      main: '#3FD294',
    },
  },
})

render(
  <StrictMode>
    <Router>
      <div>
        <Elements stripe={stripePromise}>
          <ServiceContext.Provider value={services}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <Routes>
                  <Route path="/organization/:id/*" element={<Organization />} />
                  <Route path="/server/:id/*" element={<Server />} />
                  <Route path="/local/:port/*" element={<OfflineServer />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/billing" element={<Billing />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </SnackbarProvider>
            </ThemeProvider>
          </ServiceContext.Provider>
        </Elements>
      </div>
    </Router>
  </StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
