import { get as LSGet, set as LSSet } from 'local-storage'

export const LogLevel = {
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  INFO: 'INFO',
  NONE: 'NONE',
}

function validate(val?: string) {
  if (!val) return false
  if (val.length === 0) return false
  if (!Object.keys(LogLevel).includes(val)) return false
  return true
}

export function useLogger() {
  if (!validate(LSGet('loglevel'))) {
    LSSet('loglevel', LogLevel.ERROR)
  }
  const level = LSGet<string>('loglevel')

  const info = (message?: any, ...params: any) => {
    if (level !== LogLevel.NONE) {
      console.info(message, ...params)
    }
  }

  const warn = (message?: any, ...params: any) => {
    if (level === LogLevel.WARNING || level === LogLevel.ERROR) {
      console.warn(message, ...params)
    }
  }

  const error = (message?: any, ...params: any) => {
    if (level === LogLevel.ERROR) {
      console.error(message, ...params)
    }
  }

  return [info, warn, error]
}
