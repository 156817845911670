import { Button, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { useLogger } from '../hooks/logger'
import style from './model-input.module.scss'
import { v4, validate } from 'uuid'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useRefresh } from '../hooks/refresh'
import { ServerContext, ServerSettings } from '../pages/server/ServerContext'
import { useOrganization } from '../hooks/services'

const modelToDB = {
  Team: 'teams',
}

export const ModelInput: React.FC<any> = (props: {
  model: string
  cur: any
  label: string
  onChange: Function
  org_id?: string
  InputProps?: any
}) => {
  const [info, warn, error] = useLogger()
  const [documents, setDocuments] = useState<any[]>([])
  const org = useOrganization()
  const { model, onChange, cur } = props
  const [preview, setPreview] = useState<any>(null)
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [pages, setPages] = useState<number>(2)
  const [to, setTo] = useState<any>(null)
  const elm = useRef<HTMLInputElement | null>(null)
  const serv = useContext(ServerContext)

  const fetch = async (page: number, s: string = search) => {
    if (!serv) return
    setTo(null)
    try {
      const results = await org.search(serv.organization, 'name', s, model, page, 5)
      setPages(Math.ceil(results[0].metadata[0].total / 5))
      setPage(results[0].metadata[0].page)
      setDocuments(results[0].data)
      if (page >= pages) setPage(pages)
      if (page <= 0) setPage(1)
      if (elm && elm.current) elm.current.focus()
    } catch (err) {
      //console.log(err)
      setPage(1)
      setDocuments([])
      setPages(1)
    }
  }

  const nextPage = () => {
    if (page >= pages) return
    //setPage(page + 1)
    fetch(page + 1)
  }

  const previousPage = () => {
    if (page === 1) return
    //setPage(page - 1)
    fetch(page - 1)
  }

  const doSearch = (search: string, page: number) => {
    if (to !== null) clearTimeout(to)
    setTo(
      setTimeout(() => {
        fetch(page, search)
      }, 250),
    )
  }

  useEffect(() => {
    try {
      fetch(1)
    } catch (err) {}
  }, [true])

  /*

    TODO: 
      - Add tabs along top to select source api
      - paginate results
      - Add search bar (simple search, startsWith in mongodb or sumthn)


      Should we load all of the results (w/ only name, logo, updated) then fetch on preview/set?
       -> for fast searching
  */
  let cname = cur ? cur._id : ''
  let cupdated = new Date(cur ? cur.updatedAt : Date.now())
  let clogo = ''

  // Resolve name
  if (cur && cur.vars) {
    const cbname = cur.vars.find((x: any) => x.name === 'cb-name')
    if (cbname) {
      cname = cbname
    }
  }
  if (cur && cname === cur._id) {
    if (cur.name) cname = cur.name
    else if (cur.display_name) cname = cur.display_name
    else if (cur.screen_name) cname = cur.screen_name
  }

  // Resolve logo
  if (cur && cur.vars) {
    const cblogo = cur.vars.find((x: any) => x.name === 'cb-logo')
    if (cblogo) {
      clogo = cblogo
    }
  }
  if (cur && clogo === '') {
    if (cur.logo) clogo = cur.logo
    else if (cur.avatar) clogo = cur.avatar
  }

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id={`input-${model}-select`}>{props.label}</InputLabel>
        <Select
          labelId={`input-${model}-select`}
          value={!validate((cur || {})._id || '') ? (cur || {})._id || '' : ''}
          label={props.label}
          onChange={(e: any) => {
            const doc = documents.find((x) => x._id === e.target.value)
            if (doc) {
              onChange(doc)
            } else {
              onChange({ _id: v4() })
            }
          }}
          style={{ cursor: 'pointer' }}
          className={style.select}
          data-pad={Boolean((props.InputProps || {}).endAdornment)}
          endAdornment={(props.InputProps || {}).endAdornment}
        >
          <div className={style.apiSelect}>
            {/* On click, set the api url template, then fetch the first few results. */}
            {/* template example: https://backend.nylund.us/api/v1/{MODEL} */}
            {/* Then add a way to set headers to send with every api request (for auth and stuff) */}
            <p data-selected="true">Default</p>
            {process.env.NODE_ENV === 'development' ? <p>Minnesota</p> : null}
          </div>
          <MenuItem value={''}>None</MenuItem>
          <hr />

          {/* Search bar */}
          <div style={{ paddingLeft: '40px', paddingRight: '40px', paddingTop: '10px', paddingBottom: '10px' }}>
            <TextField
              ref={elm}
              fullWidth={true}
              placeholder="Search..."
              defaultValue={search}
              onChange={(e: any) => {
                setSearch(e.target.value)
                doSearch(e.target.value, 1)
              }}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>

          {/* Display current */}
          {cur && cur._id && cur.createdAt ? (
            <MenuItem key={`modelinput-${model}-${cur._id}-cur`} value={cur._id}>
              <div className={style.menuItem}>
                <div>
                  <img src={clogo} />
                </div>
                <div>
                  <p>{cname}</p>
                  <p>{`Last update: ${cupdated.toLocaleDateString()} ${cupdated.toLocaleTimeString()}`}</p>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    className={style.previewBtn}
                    onClick={(e) => {
                      e.stopPropagation()
                      setPreview(cur)
                    }}
                  >
                    Preview
                  </Button>
                </div>
              </div>
            </MenuItem>
          ) : null}

          {documents.length === 0 ? (
            <p style={{ width: '100%', textAlign: 'center', margin: '15px 0px' }}>No documents found</p>
          ) : null}
          {documents.map((val: any, index: number) => {
            let name = val._id
            let updated = new Date(val.updatedAt)
            let logo = ''

            // Resolve name
            if (val.vars) {
              const cbname = val.vars.find((x: any) => x.name === 'cb-name')
              if (cbname) {
                name = cbname
              }
            }
            if (name === val._id) {
              if (val.name) name = val.name
              else if (val.display_name) name = val.display_name
              else if (val.screen_name) name = val.screen_name
            }

            // Resolve logo
            if (val.vars) {
              const cblogo = val.vars.find((x: any) => x.name === 'cb-logo')
              if (cblogo) {
                logo = cblogo
              }
            }
            if (logo === '') {
              if (val.logo) logo = val.logo
              else if (val.avatar) logo = val.avatar
            }

            if (cur && val._id === cur._id) return null

            return (
              <MenuItem key={`modelinput-${model}-${val._id}`} value={val._id}>
                <div className={style.menuItem}>
                  <div>
                    <img src={logo} />
                  </div>
                  <div>
                    <p>{name}</p>
                    <p>{`Last update: ${updated.toLocaleDateString()} ${updated.toLocaleTimeString()}`}</p>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      className={style.previewBtn}
                      onClick={(e) => {
                        e.stopPropagation()
                        setPreview(val)
                      }}
                    >
                      Preview
                    </Button>
                  </div>
                </div>
              </MenuItem>
            )
          })}

          {/* Page things */}
          <div className={style.pageFooter}>
            <ArrowBackIosNewIcon
              fontSize="small"
              data-disabled={page <= 1 ? 'true' : 'false'}
              onClick={() => {
                previousPage()
              }}
            />
            <p style={{ userSelect: 'none' }}>
              {page} / {pages}
            </p>
            <ArrowForwardIosIcon
              fontSize="small"
              data-disabled={page >= pages ? 'true' : 'false'}
              onClick={() => {
                nextPage()
              }}
            />
          </div>
        </Select>
      </FormControl>
      <Dialog
        onClose={() => {
          setPreview(null)
        }}
        open={preview !== null}
      >
        {preview !== null ? (
          <>
            <DialogTitle>
              {props.model} Preview [{preview._id}]
            </DialogTitle>
            <div className={style.docPreview}>
              {JSON.stringify(preview, undefined, 4)
                .split('\n')
                .map((line, index) => {
                  return (
                    <div className={style.previewLine} key={`preview-${preview._id}-l${index}`}>
                      <span>{index + 1}</span>
                      <span>{line}</span>
                    </div>
                  )
                })}
            </div>
          </>
        ) : null}
      </Dialog>
    </div>
  )
}
