import axios, { AxiosResponse } from 'axios'
import { Organization } from '../types/Organization'
import { AuthService } from './auth.service'

export class OrganizationService {
  constructor(private auth: AuthService) {}

  createOrganization = (data: { name: string; color: string; description?: string }): Promise<Organization> => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/organizations`,
      data,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  getOrganization = (id: string, qs?: any): Promise<Organization> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/organizations/${id}`,
      params: qs,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  getOrganizations = (): Promise<Organization[]> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/organizations`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  deleteOrganization = (id: string): Promise<any> => {
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/organizations/${id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  updateOrganization = (
    id: string,
    data: {
      name?: string
      description?: string
    },
  ): Promise<Organization> => {
    return axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/organizations/${id}`,
      data,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  updateRole = (org_id: string, user_id: string, role: 'admin' | 'member'): Promise<Organization> => {
    return axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/organizations/${org_id}/members/${user_id}`,
      data: {
        role,
      },
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  removeUser = (org_id: string, user_id: string): Promise<Organization> => {
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/organizations/${org_id}/members/${user_id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  search = (
    org_id: string,
    field: string,
    value: string,
    model: string,
    page: number,
    limit: number,
  ): Promise<
    {
      data: any[]
      metadata: {
        limit: number
        page: number
        total: number
      }[]
    }[]
  > => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/search?model=${model}&organization=${org_id}&field=${field}&search=${value}&page=${page}&limit=${limit}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }
}
