import { createContext } from 'react'
import { AuthService } from './auth.service'
import { InvitationService } from './invitation.service'
import { OrganizationService } from './organization.service'
import { PlatformService } from './platform.service'
import { ServerService } from './server.service'
import { TeamService } from './team.service'
import { WebsocketService } from './websocket.service'
import { StripeService } from './stripe.service'

export * from './auth.service'
export * from './websocket.service'
export * from './platform.service'
export * from './organization.service'
export * from './invitation.service'
export * from './platform.service'
export * from './server.service'
export * from './team.service'
export * from './stripe.service'

export interface Services {
  invitation: InvitationService
  auth: AuthService
  organization: OrganizationService
  platform: PlatformService
  server: ServerService
  websocket: WebsocketService
  team: TeamService
  stripe: StripeService
}

export const ServiceContext = createContext<Services | null>(null)
