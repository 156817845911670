import axios, { AxiosResponse } from 'axios'
import { Invitation } from '../types/Invitation'
import { AuthService } from './auth.service'

export class InvitationService {
  constructor(private auth: AuthService) {}

  sendInvitation = (data: { email: string; organization: string }): Promise<Invitation> => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/invitations`,
      data,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  getInvitation = (id: string): Promise<Invitation> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/invitations/${id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  getInvitations = (org_id?: string): Promise<Invitation[]> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/${org_id ? `organizations/${org_id}/invitations` : 'invitations'}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  deleteInvitation = (id: string): Promise<any> => {
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/invitations/${id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  acceptInvitation = (id: string): Promise<any> => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/invitations/${id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }
}
