// External Dependencies
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { Routes, Route, useLocation, Navigate, useParams, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

// Icons
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard'
import AccountCircle from '@mui/icons-material/AccountCircle'
import StorageIcon from '@mui/icons-material/Storage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MonitorIcon from '@mui/icons-material/Monitor'

// Components
import { ConnectionGraph } from '../../components/connection-graph'
import { AvatarGroup } from '../../components/avatar-group'
import { ListItem } from '../../components/list-item'

// Pages
import { Dashboard } from './dashboard'
import { NotFound } from '../not-found'
import { Overlay } from './overlay'

// Assets
import sidebarImage from '../../assets/sidebar.jpeg'
import style from './server.module.scss'

// Hooks
import { useLogger } from '../../hooks/logger'
import { useServices } from '../../hooks/services'

import { useRefresh } from '../../hooks/refresh'
import { ServerContext } from './ServerContext'
import lstyle from './loading.module.scss'
import logo from '../../assets/logo.png'
import { LinearProgress, Stack } from '@mui/material'
import { useTitle } from '../../hooks/title'
import { useSnackbar } from 'notistack'
import { set as LSSet } from 'local-storage'
import { Unauthenticated } from '../login'
import { Organization } from '../../types/Organization'

export const OfflineServer: React.FC<any> = () => {
  const { auth, websocket, platform, organization, server } = useServices()
  const { pathname } = useLocation()
  const { port } = useParams()
  const [info, warn, error] = useLogger()
  const [refresh, setRefresh] = useState(false)
  const [connections, setConnections] = useState<any[]>([])
  const [disconnected, setDisconnected] = useState(false)
  const [org, setOrg] = useState<string | null>(null)
  const navigate = useNavigate()

  const basePath = `/local/${port}`

  let PORT: number = 0
  try {
    PORT = Number(port)
  } catch (err) {
    return <p>Port not a number.</p>
  }

  useRefresh(() => {
    websocket.getAllConnections((cons: any[]) => {
      setConnections(cons)
    })
  }, 1000)

  useEffect(() => {
    const disconHandler = () => {
      setDisconnected(true)
    }

    websocket.io.on('disconnect', disconHandler)

    return () => {
      websocket.io.removeListener('disconnect', disconHandler)
    }
  }, [websocket.loggedIn])

  if (!websocket.loggedIn || !org)
    return (
      <OfflineLoading
        port={PORT}
        onLoggedIn={(org_id: string) => {
          navigate(`/local/${port}/dashboard`)
          setRefresh(!refresh)
          setOrg(org_id)
        }}
      />
    )

  return (
    <ServerContext.Provider value={{ organization: org }}>
      <div className={style.server}>
        {disconnected ? (
          <div className={style.offline}>
            <p>The server you were connected to went offline.</p>
          </div>
        ) : null}
        <div className={style.sidebar}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
              <StorageIcon />
              <p>Local Server</p>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <p style={{ textAlign: 'center', margin: '15px' }}>No other servers found.</p>
            </AccordionDetails>
          </Accordion>
          <ListItem
            Icon={() => <DeveloperBoardIcon />}
            title={'Dashboard'}
            link={`${basePath}/dashboard`}
            active={pathname === `${basePath}/dashboard` ? true : false}
          />
          <ListItem
            Icon={() => <MonitorIcon />}
            title={'Overlay'}
            link={`${basePath}/overlay`}
            active={pathname === `${basePath}/overlay` ? true : false}
          />
          <img src={sidebarImage} />
          <p style={{ position: 'absolute', bottom: '20px' }}>
            Server Version: {websocket.info ? 'v' + websocket.info.version : 'Unknown'}
          </p>
        </div>
        <div data-disconnected={disconnected ? 'true' : 'false'}>
          {/* Shared navbar [toolset] */}
          <div className={style.navbar}>
            {/* Page Title */}
            <Routes>
              <Route
                path="/login"
                element={
                  <div>
                    <AccountCircle style={{ fontSize: 35 }} />
                    <h2 style={{ marginLeft: 10 }}>Login</h2>
                  </div>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <div>
                    <DeveloperBoardIcon style={{ fontSize: 35 }} />
                    <h2 style={{ marginLeft: 10 }}>Dashboard</h2>
                  </div>
                }
              />
              <Route
                path="/overlay"
                element={
                  <div>
                    <MonitorIcon style={{ fontSize: 35 }} />
                    <h2 style={{ marginLeft: 10 }}>Overlay</h2>
                  </div>
                }
              />
            </Routes>

            <div className={style.navbarRight}>
              <AvatarGroup
                urls={connections.map((val) => {
                  // eventually, we can pass in avatars to control board connections
                  if (val.type === 'CONTROLBOARD') return 'https://www.dropbox.com/s/oi3axn8oqbnjcsj/umn.png?dl=1'
                  return null
                })}
                _ids={connections.map((val) => {
                  if (val.type === 'CONTROLBOARD') return val.email ?? val.id
                  return null
                })}
              />
              <ConnectionGraph width={200} color={'BLACK'} />
              {/*<Avatar
              url="https://www.dropbox.com/s/oi3axn8oqbnjcsj/umn.png?dl=1"
              size="MEDIUM"
              _id="TEST_ID"
              onClick={(e: React.MouseEvent) => console.log('avatar clicked', e)}
            />*/}
            </div>
          </div>

          {/* Router */}
          <div className={style.router}>
            <Routes>
              {/* server connection page; redirect to /dashboard on success */}
              <Route path="/dashboard" element={<Dashboard websocket={websocket} />} />
              <Route path="/overlay" element={<Overlay websocket={websocket} auth={auth} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>

          {/* Test objects */}
          {/*<Avatar
          url="https://www.dropbox.com/s/oi3axn8oqbnjcsj/umn.png?dl=1"
          size="LARGE"
          _id="TEST_ID"
          onClick={(e: React.MouseEvent) => console.log('avatar clicked', e)}
        />*/}
          {/*<Card
          color="#7a0019"
          Icon={() => <SensorsIcon />}
          title="Connection Status"
          description="Excellent"
          Body={() => <p>Body here</p>}
          expandable={true}
        />*/}
          {/*<TeamCard
          avatar="https://www.dropbox.com/s/oi3axn8oqbnjcsj/umn.png?dl=1"
          name="UMN Maroon"
          color_primary="#7a0019"
          color_secondary="#ffcc33"
          roster={['chez', 'peej', 'rad']}
          _id="TEST_ID"
          onEdit={(_id: string) => console.log('editing', _id)}
          onDelete={(_id: string) => console.log('deleting', _id)}
        />*/}
        </div>
      </div>
    </ServerContext.Provider>
  )
}

export const OfflineLoading: React.FC<any> = (props: { port: number; onLoggedIn: (org_id: string) => void }) => {
  const { websocket, server: serverService, auth, organization } = useServices()
  const [status, setStatus] = useState('Retrieving server info...')
  const [error, setError] = useState<string | null>(null)
  const [org, setOrg] = useState<string | null>(null)
  const [orgSel, setOrgSel] = useState<string | null>(null)
  const [orgs, setOrgs] = useState<Organization[]>([])
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  useTitle('Connecting to server...')

  if (!auth.getUser().loggedIn || auth.getToken().length === 0) {
    enqueueSnackbar('Not logged in.', { variant: 'error' })
    LSSet('postlogin', location.pathname)
    Unauthenticated(`/local/${props.port}`, navigate)
    return null
  }

  useEffect(() => {
    organization
      .getOrganizations()
      .then((orgs: Organization[]) => {
        setOrgs(orgs)
      })
      .catch((err) => {
        if (err.message.includes('status code 401')) {
          enqueueSnackbar('Not logged in.', { variant: 'error' })
          LSSet('postlogin', location.pathname)
          Unauthenticated(`/local/${props.port}`, navigate)
        } else {
          enqueueSnackbar('Backend offline.', { variant: 'error' })
        }
      })
  }, [true])

  useEffect(() => {
    const handlers: any = {}

    websocket.connect(`http://localhost:${props.port}`, {
      autoConnect: false,
      timeout: 10000,
      transports: ['websocket'],
    })

    handlers['info'] = (info: any) => {
      websocket.info = info
    }

    handlers['connect'] = () => {
      setStatus('Logging in...')
      websocket
        .login(true)
        .then((val) => {
          setStatus('Logged in!')
          websocket.loggedIn = true
        })
        .catch((err) => {
          setError(err.message)
        })
    }

    handlers['connect_error'] = (err: any) => {
      setError('Failed to connect.')
      websocket.io.close()
    }

    websocket.io.on('connect', handlers['connect'])
    websocket.io.on('connect_error', handlers['connect_error'])
    websocket.io.on('info', handlers['info'])

    websocket.io.connect()

    return () => {
      websocket.io.removeListener('info', handlers['info'])
      websocket.io.removeListener('connect', handlers['connect'])
      websocket.io.removeListener('connect_error', handlers['connect_error'])
    }
  }, [true])

  useEffect(() => {
    if (websocket.loggedIn && org) {
      setTimeout(() => {
        props.onLoggedIn(org)
      }, 1000)
    }
  }, [websocket.loggedIn, org])

  return (
    <div className={lstyle.loading}>
      {org === null ? (
        <div className={lstyle.selectOrg}>
          <p style={{ color: '#444', fontWeight: 'normal', fontSize: '18px' }}>Select an organization:</p>
          <Select
            fullWidth
            defaultValue={''}
            onChange={(e: any) => {
              setOrgSel(e.target.value)
            }}
            sx={{ margin: '20px 0px' }}
          >
            {orgs.map((val) => {
              return (
                <MenuItem value={val._id} key={`orgselect-serv-${val._id}`}>
                  {val.name} - {val.members.length} Members
                </MenuItem>
              )
            })}
          </Select>

          <Button
            variant="contained"
            disabled={orgSel === null}
            onClick={() => {
              if (orgSel) {
                setOrg(orgSel)
              }
            }}
          >
            Connect
          </Button>
        </div>
      ) : (
        <>
          <img src={logo} width={200} />
          <Stack sx={{ width: '80%', color: 'grey.500', marginTop: '50px' }} spacing={2}>
            {error === null ? (
              <>
                <p>{status}</p>
                {status === 'Logged in!' ? (
                  <LinearProgress variant="determinate" value={100} color="secondary" />
                ) : (
                  <LinearProgress color="primary" />
                )}
              </>
            ) : (
              <>
                <p>{error}</p>
                <LinearProgress variant="determinate" color="error" value={100} />
              </>
            )}
          </Stack>
        </>
      )}
    </div>
  )
}
