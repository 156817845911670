import { useEffect } from 'react'

export function useTitle(title: string) {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title + ' | Nylund Development' //(process.env.NODE_ENV === 'development' ? ' - Development Mode' : ' | Nylund Development')
    return () => {
      document.title = prevTitle
    }
  }, [true]) // Don't let page state changes affect the site title
}
