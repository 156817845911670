import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import style from './organization.module.scss'
import { Organization } from '../../types/Organization'
import { useInvitation } from '../../hooks/services'

export function getEmailRegex() {
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
}

export const Invite: React.FC<any> = (props: { org: Organization; open: boolean; onClose: () => void }) => {
  const { org, open, onClose } = props
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [valid, setValid] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const invitation = useInvitation()

  const { enqueueSnackbar } = useSnackbar()

  return (
    <Dialog
      onClose={() => {
        setLoading(false)
        onClose()
      }}
      open={open}
      className={style.invite}
    >
      {loading ? <LinearProgress /> : null}
      <DialogTitle>Invite user</DialogTitle>
      <DialogContent style={{ padding: '10px 20px', minWidth: '350px' }}>
        <TextField
          fullWidth
          error={(!valid && email.length > 0) || error !== null}
          helperText={!valid && email.length > 0 && error === null ? 'Invalid email' : error}
          type="email"
          label="Email"
          placeholder="Email"
          value={email}
          disabled={loading}
          onChange={(e) => {
            setValid(getEmailRegex().test(e.target.value))
            setEmail(e.target.value)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          disabled={loading || !valid}
          onClick={() => {
            setLoading(true)
            invitation
              .sendInvitation({
                email,
                organization: org._id,
              })
              .then((val) => {
                setValid(true)
                setLoading(false)
                onClose()
                enqueueSnackbar('User invited!', { variant: 'success' })
              })
              .catch((err) => {
                setValid(false)
                setLoading(false)
                if (err.response && err.response.data) setError(err.response.data.error)
              })
          }}
        >
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  )
}
