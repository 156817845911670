import { useTitle } from '../../hooks/title'
import style from './not-found.module.scss'

export const NotFound: React.FC = () => {
  useTitle('Not Found')
  return (
    <div className={style.notfound}>
      <p>404 |</p>
      <p>Not Found</p>
    </div>
  )
}
