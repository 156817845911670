import { useContext } from 'react'
import {
  AuthService,
  InvitationService,
  OrganizationService,
  PlatformService,
  ServerService,
  ServiceContext,
  Services,
  WebsocketService,
  TeamService,
  StripeService,
} from '../services'

export function useServices() {
  return useContext(ServiceContext) as Services
}

export function useWebsocket() {
  return useContext(ServiceContext)?.websocket as WebsocketService
}

export function useAuth() {
  return useContext(ServiceContext)?.auth as AuthService
}

export function useOrganization() {
  return useContext(ServiceContext)?.organization as OrganizationService
}

export function useTeam() {
  return useContext(ServiceContext)?.team as TeamService
}

export function useInvitation() {
  return useContext(ServiceContext)?.invitation as InvitationService
}

export function usePlatform() {
  return useContext(ServiceContext)?.platform as PlatformService
}

export function useServer() {
  return useContext(ServiceContext)?.server as ServerService
}

export function useStripe() {
  return useContext(ServiceContext)?.stripe as StripeService
}
