import axios, { AxiosResponse } from 'axios'
import { Server } from '../types/Server'
import { AuthService } from './auth.service'

export class ServerService {
  constructor(private auth: AuthService) {}

  deployServer = (org_id: string): Promise<Server> => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/servers/${org_id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  getServer = (id: string, populate: boolean): Promise<Server> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/servers/${id}${populate ? '?populate=true' : ''}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  getServers = (org_id: string, populate: boolean): Promise<Server[]> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/organizations/${org_id}/servers${populate ? '?populate=true' : ''}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  updateServer = (
    id: string,
    data: {
      name?: string
      color?: string
    },
    populate: boolean = false,
  ): Promise<Server> => {
    return axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/servers/${id}${populate ? '?populate=true' : ''}`,
      data,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  /**
   * @deprecated Use stripe to cancel subscriptions. Servers will auto-delete when subscriptions expire.
   */
  deleteServer = (id: string): Promise<any> => {
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/servers/${id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  sendCommand = (id: string, command: 'start' | 'stop' | 'restart'): Promise<any> => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/servers/${id}/${command}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }
}
