import axios, { AxiosResponse } from 'axios'
import { Platform } from '../types/Platform'
import { AuthService } from './auth.service'

export class PlatformService {
  constructor(private auth: AuthService) {}

  get = (baseUrl: string, resource: string, headers: any = {}, params: any = {}) => {
    return axios({
      method: 'get',
      url: [baseUrl, 'v2', resource].join('/'),
      params,
      headers,
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  createPlatform = (data: {
    baseUrl: string
    organization: string
    name: string
    color: string
    apiKey?: string
  }): Promise<Platform> => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/platforms`,
      data,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  getPlatform = (id: string): Promise<Platform> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/platforms/${id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  getPlatforms = (org_id: string): Promise<Platform[]> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/organizations/${org_id}/platforms`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  deletePlatform = (id: string): Promise<any> => {
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/platforms/${id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  updatePlatform = (
    id: string,
    data: {
      baseUrl?: string
      organization?: string
      name?: string
      apiKey?: string
    },
  ): Promise<Platform> => {
    return axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/platforms/${id}`,
      data,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }
}
