import style from './overlay.module.scss'
import { IOverlay } from '../../../services'
import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  Modal,
  InputAdornment,
  Fab,
} from '@mui/material'
import { Box } from '@mui/system'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import SendIcon from '@mui/icons-material/Send'
import { SceneController } from '../../../components/scene-controller'
import { useTitle } from '../../../hooks/title'
import { v4 } from 'uuid'
import { ModelInput } from '../../../components/model-input'
import { useServices } from '../../../hooks/services'
import { useRefresh } from '../../../hooks/refresh'

const removeDupes = (lst: any[]) => {
  const newList: any[] = []
  for (let i = 0; i < lst.length; i++) if (!newList.includes(lst[i])) newList.push(lst[i])
  return newList
}

export const Overlay: React.FC<any> = () => {
  const [refresh, setRefresh] = useState(true)
  const [groups, setGroups] = useState([] as string[])
  const [overlayConnections, setOverlayConnections] = useState([] as any[])
  const [overlay, setOverlay] = useState<IOverlay>()
  const [baseSelected, setBaseSelected] = useState('')
  const [updatingSource, setUpdatingSource] = useState(0) // 0: blank, 1: updating, 2: fail, 3: success
  const [sceneData, setSceneData] = useState<{ name: string; data: any }[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const { websocket, auth } = useServices()
  useTitle('Overlay')

  const updateRefresh = () => {
    setRefresh(!refresh)
  }

  const handleOverlayChange = (event: any) => {
    if (!event.target.value) return
    setBaseSelected(event.target.value)

    const ovl = overlayConnections.find((x) => x.id === event.target.value)
    console.log(ovl)
    setOverlay(ovl)
    setSceneData(
      ovl
        ? ovl.scenes.map((val: any, index: any) => {
            return {
              name: val.name,
              data: val.data ?? {},
            }
          })
        : [],
    )
  }

  const handleSourceChange = (event: any) => {
    if (!overlay) return
    const oldSource = overlay.group_id
    setUpdatingSource(1)
    setOverlay({ ...overlay, group_id: event.target.value })
    websocket.assign(overlay.id, event.target.value, (err?: Error) => {
      if (!err) {
        updateRefresh()
        setUpdatingSource(3)
      } else {
        setOverlay({ ...overlay, group_id: oldSource })
        setUpdatingSource(2)
      }

      setTimeout(() => {
        setUpdatingSource(0)
      }, 5000)
    })
  }

  useRefresh(() => {
    websocket.getGroups((groups: string[], err?: Error) => {
      if (err) {
        console.log(`Failed to get groups. ${err.message}`)
      } else {
        setGroups(groups.filter((x) => x !== null))
      }
    })
    websocket.getConnections('OVERLAY', (connections: any[], err?: Error) => {
      if (err) {
        console.log(`Failed to get overlay connections. ${err.message}`)
      } else {
        setOverlayConnections(connections)
      }
    })
  }, 1000)

  const renderOverlays = (match: string) => {
    const overs = overlayConnections
      .filter((x) => x.group_id === match)
      .map((x) => {
        return (
          <MenuItem key={`overlayselect-${x.id}`} value={x.id}>
            {x.name}
          </MenuItem>
        )
      })
    return [<ListSubheader>{match.length > 0 ? match : 'Unassigned'}</ListSubheader>, overs]
  }

  return (
    <div className={style.overlayRoot}>
      <Box sx={{ minWidth: 250 }}>
        <FormControl sx={{ minWidth: 250 }}>
          <InputLabel id="overlay-page-current">
            {baseSelected === null || baseSelected === '' ? 'Select overlay' : 'Overlay'}
          </InputLabel>
          <Select
            labelId="overlay-page-current"
            value={baseSelected === null ? '' : baseSelected}
            label={baseSelected === null || baseSelected === '' ? 'Select overlay' : 'Overlay'}
            onChange={handleOverlayChange}
          >
            {overlayConnections.length === 0 ? (
              <p style={{ width: '100%', padding: '10px 0', textAlign: 'center', userSelect: 'none' }}>
                No overlays connected.
              </p>
            ) : (
              ['', ...groups].map((val, index) => {
                return overlayConnections.filter((x) => x.group_id === val).length === 0 ? null : renderOverlays(val)
              })
            )}
          </Select>
        </FormControl>
      </Box>
      {baseSelected === null || overlay === undefined ? null : (
        <div className={style.overlayBox}>
          <div>
            <Box sx={{ minWidth: 250, height: 80 }}>
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel id="overlay-page-source">Source</InputLabel>
                <Select
                  labelId="overlay-page-source"
                  value={overlay !== null ? overlay.group_id : ''}
                  label="Source"
                  onChange={handleSourceChange}
                >
                  {removeDupes([
                    'Group 1',
                    'Group 2',
                    'Group 3',
                    'Group 4',
                    'Group 5',
                    'Group 6',
                    'Group 7',
                    'Group 8',
                    'Group 9',
                    'Group 10',
                    ...groups,
                  ]).map((val, index) => {
                    return (
                      <MenuItem key={`overlaysource-${val}-${index}`} value={val}>
                        {val}
                      </MenuItem>
                    )
                  })}
                </Select>
                {updatingSource === 0 ? null : updatingSource === 1 ? (
                  <FormHelperText>Saving...</FormHelperText>
                ) : updatingSource === 2 ? (
                  <FormHelperText>Failed!</FormHelperText>
                ) : (
                  <FormHelperText>Success!</FormHelperText>
                )}
              </FormControl>
            </Box>
            {overlay.scenes.length === 0 ? null : (
              <Box sx={{ minWidth: 250, height: 80 }}>
                <Button variant="text" onClick={() => setModalOpen(true)}>
                  Open scene controller
                </Button>
              </Box>
            )}
            <Modal
              className={style.modalBox}
              open={modalOpen}
              onClose={() => {
                setModalOpen(false)
              }}
            >
              <div className={style.modal}>
                <SceneController websocket={websocket} overlay={overlay} />
              </div>
            </Modal>
          </div>
          {overlay.scenes.length === 0 ? (
            <p>No scenes found.</p>
          ) : (
            <div>
              <div className={style.sceneController}>
                <SceneController websocket={websocket} overlay={overlay} />
              </div>
              <div className={style.variables}>
                <Fab
                  className={style.fab}
                  color="secondary"
                  variant="extended"
                  onClick={() => {
                    sceneData.forEach((scene) => {
                      websocket.sendSceneData(overlay.group_id, scene.name, scene.data)
                    })
                  }}
                >
                  <SendIcon sx={{ mr: 1 }} />
                  Send Data
                </Fab>
                {overlay.scenes.map((scene, scene_index) => {
                  return scene === null || scene === undefined ? null : (
                    <div key={`scenevars-${scene.name}-${scene_index}`}>
                      <p>{scene.name}</p>
                      {/* Plop buttons right where this comment is */}
                      <div className={style.sceneButtons}>
                        {scene.buttons
                          ? scene.buttons.map((val: string) => {
                              return (
                                <Button
                                  key={`scene-${scene_index}-btn-${val}`}
                                  variant="contained"
                                  onClick={() => {
                                    websocket.execute(overlay.group_id, scene.name, val)
                                  }}
                                >
                                  {val}
                                </Button>
                              )
                            })
                          : null}
                      </div>

                      {scene.dataFormat === null || scene.dataFormat === undefined
                        ? null
                        : Object.keys(scene.dataFormat).map((sceneDataKey, formatIndex) => {
                            return (
                              <div className={style.sceneVar} key={`scenekey-${sceneDataKey}-${formatIndex}`}>
                                <p>{sceneDataKey}</p>
                                {DataValue({
                                  obj: scene.dataFormat[sceneDataKey],
                                  cur: sceneData.find((x) => x.name === scene.name)?.data[sceneDataKey],
                                  onUpdate: (value: any) => {
                                    const curScene = sceneData.find((x) => x.name === scene.name)
                                    if (!curScene) return
                                    const allData = sceneData.filter((x) => x.name !== scene.name)
                                    curScene.data[sceneDataKey] = value
                                    allData.push(curScene)
                                    setSceneData(allData)
                                  },
                                })}
                              </div>
                            )
                          })}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

/*

  obj: data format to follow
  cur: current data
  onUpdate: function taking a value to be assigned to the key used to call this function

  Supported types: String, String[], Number, Number[], Boolean, Team, Team[], Player, Player[], League, League[], Season, Season[], Match, Match[], Game, Game[]

  oh muck, here we go...
*/
export const DataValue: React.FC<any> = (props: {
  obj: any
  cur: any
  onUpdate: Function
  ignoreAccordion?: boolean
}) => {
  const { obj, onUpdate } = props
  const ignoreAccordion = props.ignoreAccordion ?? false

  let cur = props.cur

  // THE EASY (but long) STUFF: string types. Create inputs for each.
  if (typeof obj === 'string') {
    if (cur === undefined) {
      if (obj.indexOf('[]') > 0) {
        cur = []
        //onUpdate(cur)
      }
    }
    if (obj === 'String') {
      if (cur === undefined) {
        cur = ''
        //onUpdate(cur)
      }
      return (
        <TextField
          label="Value"
          variant="outlined"
          defaultValue={cur}
          onBlur={(e) => {
            onUpdate(e.target.value)
          }}
        />
      )
    } else if (obj === 'String[]') {
      return (
        <div className={style.vararray}>
          {cur.map((val: string, index: number) => {
            return (
              <div key={`scenevars-stringarray-${index}-${val}`}>
                <TextField
                  label={`Index ${index}`}
                  variant="outlined"
                  defaultValue={cur[index]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            cur.splice(index, 1)
                            onUpdate(cur)
                          }}
                        >
                          Remove
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  onBlur={(e) => {
                    // Update value at index
                    cur[index] = e.target.value
                    onUpdate(cur)
                  }}
                />
              </div>
            )
          })}
          <Button
            variant="contained"
            onClick={() => {
              cur.push('')
              onUpdate(cur)
            }}
          >
            + Add Element
          </Button>
        </div>
      )
    } else if (obj === 'Number') {
      if (cur === undefined) {
        cur = 0
        //onUpdate(cur)
      }
      return (
        <TextField
          label="Value"
          variant="outlined"
          defaultValue={cur}
          type="number"
          inputProps={{ inputMode: 'numeric' }}
          onBlur={(e) => {
            onUpdate(Number(e.target.value))
          }}
        />
      )
    } else if (obj === 'Number[]') {
      return (
        <div className={style.vararray}>
          {cur.map((val: number, index: number) => {
            return (
              <div key={`svarnum-${val}-${index}`}>
                <TextField
                  label={`Index ${index}`}
                  variant="outlined"
                  defaultValue={cur[index]}
                  type="number"
                  inputProps={{ inputMode: 'numeric' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            cur.splice(index, 1)
                            onUpdate(cur)
                          }}
                        >
                          Remove
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  onBlur={(e) => {
                    // Update value at index
                    cur[index] = Number(e.target.value)
                    onUpdate(cur)
                  }}
                />
              </div>
            )
          })}
          <Button
            variant="contained"
            onClick={() => {
              const newVal = cur
              newVal.push('')
              onUpdate(newVal)
            }}
          >
            + Add Element
          </Button>
        </div>
      )
    } else if (obj === 'Boolean') {
      if (cur === undefined) {
        cur = false
        //onUpdate(cur)
      }
      return (
        <Checkbox
          value={cur === undefined ? false : cur}
          size="medium"
          onChange={(e) => {
            onUpdate(e.target.checked)
          }}
        />
      )
    } else {
      const isArray = obj.indexOf('[]') > 0
      const model = obj.replace('[]', '')

      // Check if model exists
      if ((['Team'] as string[]).includes(model)) {
        if (cur === undefined) {
          cur = null
          //onUpdate(cur)
        }
        return isArray ? (
          <div className={style.vararray}>
            {cur.map((val: any, index: number) => {
              return (
                <div key={`svar-${val._id}-${index}`} style={{ border: 'none', boxShadow: 'none' }}>
                  <ModelInput
                    label={`Index ${index}`}
                    model={model}
                    cur={val}
                    onChange={(value: any) => {
                      cur[index] = value
                      onUpdate(cur)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              cur.splice(index, 1)
                              onUpdate(cur)
                            }}
                          >
                            Remove
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )
            })}
            <Button
              variant="contained"
              onClick={() => {
                const newVal = cur
                newVal.push({ _id: v4() })
                onUpdate(newVal)
              }}
            >
              + Add Element
            </Button>
          </div>
        ) : (
          <ModelInput
            label={`Value`}
            model={model}
            cur={cur}
            onChange={(value: any) => {
              onUpdate(value)
            }}
          />
        )
      }
    }
  }

  // THE HARD-ISH STUFF: object types
  if (typeof obj === 'object' && !Array.isArray(obj)) {
    if (cur === undefined) {
      cur = {}
      //onUpdate(cur)
    }
    return (
      <div>
        {ignoreAccordion ? (
          <>
            {Object.keys(obj).map((objKey, objIndex) => {
              return (
                <div className={style.sceneVar} key={`scenekey-rec-${objKey}-${objIndex}`}>
                  <p>{objKey}</p>
                  {DataValue({
                    obj: obj[objKey],
                    cur: cur[objKey],
                    onUpdate: (value: any) => {
                      cur[objKey] = value
                      onUpdate(cur)
                    },
                  })}
                </div>
              )
            })}
          </>
        ) : (
          <Accordion key={`scenekey-recobj-${obj.toString()}`}>
            <AccordionSummary aria-controls="panel2d-content">
              <div className={style.accordionValue}>
                <Typography>Value</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0 !important' }}>
              {Object.keys(obj).map((objKey, objIndex) => {
                return (
                  <div className={style.sceneVar} key={`scenekey-rec-${objKey}-${objIndex}`}>
                    <p>{objKey}</p>
                    {DataValue({
                      obj: obj[objKey],
                      cur: cur[objKey],
                      onUpdate: (value: any) => {
                        cur[objKey] = value
                        onUpdate(cur)
                      },
                    })}
                  </div>
                )
              })}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    )
  }

  // THE OH-FUCK STUFF: array types
  if (Array.isArray(obj)) {
    if (cur === undefined) {
      cur = []
      //onUpdate(cur)
    }

    if (obj[0]) {
      // To make this look even remotely good, we need collapsable entries (accordian style, yee haw)
      return (
        <div className={style.vararray}>
          <div style={{ display: cur.length === 0 ? 'none' : undefined }}>
            {cur.map((val: any, index: number) => {
              return (
                <Accordion key={`scenekey-recarr-${val._react_id}`}>
                  <AccordionSummary aria-controls="panel2d-content">
                    <div className={style.accordionValue}>
                      <Typography>Index {index}</Typography>
                      <Button
                        variant="contained"
                        color="error"
                        size={'small'}
                        onClick={(e) => {
                          cur.splice(index, 1)
                          onUpdate(cur)
                        }}
                        style={{ marginLeft: 'auto' }}
                      >
                        Remove
                      </Button>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    {DataValue({
                      obj: obj[0],
                      cur: val,
                      onUpdate: (value: any) => {
                        cur[index] = value
                        onUpdate(cur)
                      },
                      ignoreAccordion: true,
                    })}
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
          <Button
            variant="contained"
            onClick={() => {
              cur.push({ _react_id: v4() })
              onUpdate(cur)
            }}
          >
            + Add Element
          </Button>
        </div>
      )
    }
  }

  // If we made it there, then ben either really fucked up or it's just not supported. I hope it's the second option :sweat_smile:
  return <TextField error disabled variant="filled" label="Type not supported." value="" />
}

// yoinked from MUI (https://mui.com/components/accordion/#customization)
const Accordion = styled((props) => <MuiAccordion children={''} disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
  }),
)

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))
