import axios, { AxiosResponse } from 'axios'
import { Team } from '../types/Team'
import { AuthService } from './auth.service'

export class TeamService {
  constructor(private auth: AuthService) {}

  get = (baseUrl: string, resource: string, headers: any = {}, params: any = {}) => {
    return axios({
      method: 'get',
      url: [baseUrl, 'v2', resource].join('/'),
      params,
      headers,
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  createTeam = (data: {
    organization: string
    name: string
    colors: {
      primary: string
      secondary: string
    }
    avatar?: string
    roster?: {
      name: string
      platforms: {
        platform: 'xbox' | 'playstation' | 'epic' | 'steam' | 'switch'
        primary: boolean
        id: string
      }[]
    }[]
  }): Promise<Team> => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/teams`,
      data,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  getTeam = (id: string): Promise<Team> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/teams/${id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  getTeams = (
    org_id: string,
    page?: number,
    limit?: number,
    search?: string,
  ): Promise<{ metadata: [{ limit: number; page: number; count: number }]; data: Team[] }> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/organizations/${org_id}/teams`,
      params: {
        search,
        page,
        limit,
      },
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  deleteTeam = (id: string): Promise<any> => {
    return axios({
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/teams/${id}`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }

  updateTeam = (
    id: string,
    data: {
      name?: string
      colors?: {
        primary: string
        secondary: string
      }
      avatar?: string
      roster?: {
        name: string
        platforms: {
          platform: 'xbox' | 'playstation' | 'epic' | 'steam' | 'switch'
          primary: boolean
          id: string
        }[]
      }[]
    },
  ): Promise<Team> => {
    return axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/teams/${id}`,
      data,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((res: AxiosResponse<any>) => {
      return res.data
    })
  }
}
