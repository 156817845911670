import style from './dashboard.module.scss'
import Monitor from '@mui/icons-material/Monitor'
import Gamepad from '@mui/icons-material/Gamepad'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import TuneIcon from '@mui/icons-material/Tune'
import React, { useEffect, useState } from 'react'
import { Card } from '../../../components/card'
import { Button, CircularProgress, Dialog, DialogTitle, Input, Menu, MenuItem, Select, Tooltip } from '@mui/material'
import { useTitle } from '../../../hooks/title'
import { useLogger } from '../../../hooks/logger'
import { useWebsocket } from '../../../hooks/services'
import { LoadingButton } from '@mui/lab'
import { useRefresh } from '../../../hooks/refresh'
import { AssignMenu } from '../../../components/assign-menu'
import { Explainer } from '../../../components/explainer'
import { Base } from '../../../types/Live'
import { DataValue } from '../overlay'

// NEEDS TEAM BEST OF SCORE
export const Dashboard: React.FC<any> = () => {
  const [groups, setGroups] = useState([] as string[])
  const [info, warn, error] = useLogger()
  const [overlayConnections, setOverlayConnections] = useState([] as any[])
  const [pluginConnections, setPluginConnections] = useState([] as any[])
  const [connections, setConnections] = useState<any[]>([])
  const [update, setUpdate] = useState<any>(null)
  const [updateErr, setUpdateErr] = useState<null | string>(null)
  const [updating, setUpdating] = useState(false)
  const [updated, setUpdated] = useState(false)
  const [clExpand, setClExpand] = useState(false)
  const [match, setMatch] = useState<Base.Match | null>(null)
  const [teams, setTeams] = useState<any[]>([])
  const [bestOf, setBestOf] = useState<number>(5)
  const [preview, setPreview] = useState(false)
  const websocket = useWebsocket()
  useTitle('Dashboard')

  useRefresh(() => {
    websocket.getGroups((groups: string[], err?: Error) => {
      if (err) {
        error(`Failed to get groups. ${err.message}`)
      } else {
        setGroups(groups.filter((x) => x !== null))
      }
    })
    websocket.getConnections('OVERLAY', (connections: any[], err?: Error) => {
      if (err) {
        error(`Failed to get overlay connections. ${err.message}`)
      } else {
        setOverlayConnections(connections)
      }
    })
    websocket.getConnections('PLUGIN', (connections: any[], err?: Error) => {
      if (err) {
        error(`Failed to get plugin connections. ${err.message}`)
      } else {
        setPluginConnections(connections)
      }
    })

    websocket.getAllConnections((cons: any[]) => {
      //console.log(cons)
      setConnections(cons)
    })

    websocket.io.emit('check_for_updates', (latest: any, err?: string) => {
      if (!err) {
        setUpdate(latest)
      }
    })

    /*if (match !== null) {
        websocket.io.emit('match:get', match.group_id, (mch: Base.Match) => {
          setBestOf(mch.bestOf)
          if (mch.game) {
            setTeams(mch.game.teams.map((v) => v.info))
          }
          setMatch(mch)
        })
      }*/
  }, 1000)

  useEffect(() => {
    function matchUpdated(id: string, new_match: Base.Match) {
      if (match && match.group_id === id) {
        console.log('new match incoming:', new_match)
        setMatch(new_match)
        setBestOf(new_match.bestOf)
        if (new_match.game) {
          setTeams(new_match.game.teams.map((v) => v.info))
        }
      }
    }

    function teamSet(id: string, teamnum: number, team: Base.Team) {
      if (match && match.group_id === id && teamnum >= 0) {
        if (!match.game) {
          setMatch({ ...match, game: { teams: [], winner: -1, hasWinner: false } })
        } else {
          let g = match.game
          g.teams[teamnum] = team
          setMatch({ ...match, game: g })
        }

        let tmp = teams
        while (teamnum > tmp.length) {
          tmp.push({})
        }
        tmp[teamnum] = { ...tmp[teamnum], ...team.info }
        setTeams(tmp)
      }
    }

    websocket.io.on('match:updated', matchUpdated)
    websocket.io.on('match:team_set', teamSet)

    return () => {
      websocket.io.off('match:updated', matchUpdated)
      websocket.io.off('match:team_set', teamSet)
    }
  })

  return (
    <div className={style.dashboard}>
      {update === null ? null : (
        <div className={style.update} data-expanded={String(clExpand)}>
          <p
            style={{ textAlign: 'center', color: 'white', fontWeight: 'bolder' }}
            onClick={() => setClExpand(!clExpand)}
          >
            An update is available! v{websocket.info ? websocket.info.version : '?'} -&gt; v{update.Version}. Click here
            for more details.
          </p>
          {!clExpand ? null : (
            <div>
              <div>
                <div>
                  <p>
                    <strong>Relay v{update.Version}</strong>
                  </p>
                  {update.Changelog.split('\n').map((val: any, index: number) => {
                    return <p key={`relay-update-cl-${index}`}>{val}</p>
                  })}
                  <p style={{ marginTop: '20px' }}>Updates applied now won't take effect until next reboot.</p>
                  <div>
                    <LoadingButton
                      onClick={() => {
                        setUpdateErr(null)
                        setUpdating(true)
                        websocket.io.emit('update', (err: any) => {
                          setUpdating(false)
                          if (err) {
                            setUpdateErr(err)
                          } else {
                            setUpdated(true)
                          }
                        })
                      }}
                      disabled={updateErr !== null || updated}
                      variant="contained"
                      loading={updating}
                      loadingIndicator="Updating..."
                      loadingPosition="center"
                      style={{ width: '120px' }}
                    >
                      Update
                    </LoadingButton>
                    {updating ? <CircularProgress size={24} style={{ marginLeft: '15px' }} /> : null}
                    {updateErr === null ? null : <p style={{ marginLeft: '15px' }}>{updateErr}</p>}
                    {updated ? (
                      <p style={{ marginLeft: '15px', color: 'green' }}>
                        Relay updated! Reboot the server to apply the update.
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className={style.flexRow}>
        <Card
          color="#324044"
          title={'Overlays Connected'}
          description={overlayConnections.length}
          expandable={overlayConnections.length > 0}
          Icon={() => <Monitor />}
          Body={
            <div key={`overlay-body-groups`}>
              {[...groups, ''].map((val, index) => {
                return overlayConnections.filter((x) => x.group_id === val).length === 0 ? null : (
                  <ConnectionGroup
                    key={`group-ovl-${val}`}
                    name={val.length > 0 ? val : 'Unassigned'}
                    connections={overlayConnections.filter((x) => x.group_id === val)}
                    groups={groups}
                    type={'OVERLAY'}
                  />
                )
              })}
            </div>
          }
        />
        <Card
          color="#324044"
          title={'Plugins Connected'}
          description={pluginConnections.length}
          expandable={pluginConnections.length > 0}
          Icon={() => <Gamepad />}
          Body={
            <div>
              {[...groups, ''].map((val, index) => {
                return pluginConnections.filter((x) => x.group_id === val).length === 0 ? null : (
                  <ConnectionGroup
                    key={`group-pl-${val}`}
                    name={val.length > 0 ? val : 'Unassigned'}
                    connections={pluginConnections.filter((x) => x.group_id === val)}
                    groups={groups}
                    type={'PLUGIN'}
                  />
                )
              })}
            </div>
          }
        />
        {/*<Card // Disable API card until implementation comes later
          color="#Ef9B0F"
          title={'API Health'}
          description={'Excellent'}
          expandable={true}
          Icon={() => <Storage />}
          Body={() => {
            const api = [
              { name: '/v2/teams', status: 'good' },
              { name: '/v2/players', status: 'good' },
              { name: '/v2/leagues', status: 'good' },
            ]
            return (
              <div>
                {api.map((val, index) => {
                  return (
                    <div className={style.apiRow} key={`${index}-${val.name}`}>
                      <p>{val.name}</p>
                      <p>{val.status}</p>
                    </div>
                  )
                })}
              </div>
            )
          }}
        />*/}
      </div>
      <Card
        color="#324044"
        title={'Connections'}
        description={''}
        expandable={false}
        Icon={() => <CompareArrowsIcon />}
        Body={
          <div className={style.connections}>
            <ConnectionRow
              connection={{
                name: 'Name',
                type: 'Type',
                group_id: 'Group',
                id: 'ID',
                email: 'Email',
                input: 'Packets Received',
              }}
              titleRow={true}
            />
            {connections.length > 0 ? (
              connections.map((val, index) => {
                return <ConnectionRow key={`connection-${val.id}`} connection={val} />
              })
            ) : (
              <p style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>No connections found.</p>
            )}
          </div>
        }
        style={{ height: 600, width: 'calc(100vw - 270px - 40px)', marginTop: 45 }}
      />
      <Card
        color="#324044"
        title={'Match Setup'}
        description={''}
        expandable={false}
        Icon={() => <TuneIcon />}
        Body={
          <div className={style.matchSetup}>
            <div>
              <p style={{ marginBottom: '5px', fontSize: '18px' }}>Group:</p>
              <Select sx={{ minWidth: '300px' }} value={!match ? '' : match.group_id}>
                {groups.map((val) => {
                  return (
                    <MenuItem
                      value={val}
                      key={`matchsetup-groupsel-${val}`}
                      onClick={() => {
                        websocket.io.emit('match:get', val, (mtch: Base.Match) => {
                          if (mtch.game) {
                            setTeams(mtch.game.teams.map((val) => val.info))
                          }
                          setBestOf(mtch.bestOf)
                          setMatch(mtch)
                        })
                      }}
                    >
                      {val}
                    </MenuItem>
                  )
                })}
              </Select>
            </div>
            {match === null ? null : (
              <>
                <Button
                  variant="outlined"
                  style={{ marginBottom: '10px' }}
                  onClick={() => {
                    setPreview(true)
                  }}
                >
                  View current match data
                </Button>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                  <p style={{ marginRight: '10px' }}>Best of </p>
                  <Input
                    value={bestOf}
                    style={{ width: '50px' }}
                    type="number"
                    inputProps={{ min: '1', step: '2' }}
                    onChange={(e: any) => {
                      let val = Number(e.target.value)
                      if (val < 1) val = 5
                      if (val % 2 === 0) val += 1
                      setBestOf(val)
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                  <p style={{ marginRight: '10px' }}>Teams</p>

                  <div className={style.matchTeams}>
                    {DataValue({
                      obj: 'Team[]',
                      cur: teams ? teams.filter((x) => x !== null && x !== undefined) : [],
                      onUpdate: (val: any) => {
                        setTeams(val)
                      },
                    })}
                  </div>
                </div>
                {teams && teams.length > 0 && match.game ? (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                    <p style={{ marginRight: '10px' }}>Series Score</p>

                    <div className={style.matchTeams}>
                      <DataValue
                        // {key={JSON.stringify(
                        // teams
                        // .map((val: any) => {
                        // if (!match.game || !match.game.teams.find((x) => x && x.info && x.info._id === val._id))
                        // return undefined
                        // return {
                        // [val.name]: match.game.teams.find((x) => x && x.info && x.info._id === val._id)!.series,
                        // }
                        // })
                        // .reduce((prev, next) => {
                        // return { ...prev, ...next }
                        // }),
                        // )}}
                        obj={teams
                          .map((val: any) => {
                            if (!match.game || !match.game.teams.find((x) => x && x.info && x.info._id === val._id))
                              return undefined
                            return { [val.name]: 'Number' }
                          })
                          .reduce((prev, next) => {
                            return { ...prev, ...next }
                          })}
                        ignoreAccordion={true}
                        cur={teams
                          .map((val: any) => {
                            if (!match.game || !match.game.teams.find((x) => x && x.info && x.info._id === val._id))
                              return undefined
                            return {
                              [val.name]: match.game.teams.find((x) => x && x.info && x.info._id === val._id)!.series,
                            }
                          })
                          .reduce((prev, next) => {
                            return { ...prev, ...next }
                          })}
                        onUpdate={(val: any) => {
                          if (match.game) {
                            setMatch({
                              ...match,
                              game: {
                                ...match.game,
                                teams: match.game!.teams.map((t) => {
                                  return {
                                    ...t,
                                    series: val[t.info.name],
                                  }
                                }),
                              },
                            })
                            console.log('new match:', {
                              ...match,
                              game: {
                                ...match.game,
                                teams: match.game!.teams.map((t) => {
                                  return {
                                    ...t,
                                    series: val[t.info.name],
                                  }
                                }),
                              },
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <Button
                  variant="contained"
                  onClick={() => {
                    websocket.io.emit('match:update', match.group_id, { ...match, bestOf })
                    console.log(`updated match:`, { ...match, bestOf })
                    setMatch({ ...match, bestOf })

                    // Update teams
                    for (let i = 0; i < teams.length; i++) {
                      websocket.io.emit('match:set_team', match.group_id, i, { info: teams[i] }, (err: any) => {
                        if (err) console.log(`error setting team ${i}`, err)
                        else console.log(`updated team ${i} with `, { info: teams[i] })
                      })
                    }
                  }}
                  style={{ marginBottom: '20px' }}
                >
                  Update Match Data
                </Button>
                <Dialog
                  onClose={() => {
                    setPreview(false)
                  }}
                  open={preview}
                  className={style.dialog}
                  PaperProps={{ style: { maxWidth: '1200px', width: '100%' } }}
                >
                  {preview ? (
                    <>
                      <DialogTitle>Current Match Data</DialogTitle>
                      <div className={style.docPreview}>
                        {JSON.stringify(match, undefined, 4)
                          .split('\n')
                          .map((line, index) => {
                            return (
                              <div className={style.previewLine} key={`matchpreview-l${index}`}>
                                <span>{index + 1}</span>
                                <span>{line}</span>
                              </div>
                            )
                          })}
                      </div>
                    </>
                  ) : null}
                </Dialog>
              </>
            )}
          </div>
        }
        style={{ height: 600, width: 'calc(100vw - 270px - 40px)', marginTop: 45 }}
      />
      <div className={style.padBottom}></div>
    </div>
  )
}

const ConnectionRow: React.FC<any> = (props: { connection: any; titleRow?: boolean }) => {
  let { connection, titleRow } = props
  titleRow = titleRow === undefined ? false : titleRow

  return (
    <div className={style.conRow}>
      <p>
        {connection.name ?? 'None'}{' '}
        {titleRow ? (
          <Explainer
            title={'Name'}
            body={'The display name given to a connection upon login. Only applies to overlays and plugins.'}
          />
        ) : null}
      </p>
      <p>
        {connection.type}
        {titleRow ? (
          <Explainer title={'Type'} body={'The type of connection. Can either be CONTROLBOARD, OVERLAY, or PLUGIN.'} />
        ) : null}
      </p>
      <p>
        {connection.id}
        {titleRow ? (
          <Explainer
            title={'ID'}
            body={"The connection's Socket ID. Used to assign connections to different groups."}
          />
        ) : null}
      </p>
      <p>
        {connection.group_id ?? 'None'}
        {titleRow ? (
          <Explainer
            title={'Group'}
            body={'The group that the connection belongs to. Only applies to overlays and plugins.'}
          />
        ) : null}
      </p>
      <p>
        {connection.email}
        {titleRow ? (
          <Explainer title={'Email'} body={'The email of the user that authenticated the connection.'} />
        ) : null}
      </p>
      <p>
        {connection.input ?? 0}
        {titleRow ? (
          <Explainer
            title={'Packets Received'}
            body={'The number of packets received from the connection. Only applies to plugins.'}
          />
        ) : null}
      </p>
    </div>
  )
}

const ConnectionGroup: React.FC<any> = (props: {
  connections: any[]
  name: string
  groups: string[]
  type: 'PLUGIN' | 'OVERLAY'
}) => {
  const websocket = useWebsocket()
  const { connections, name } = props
  const [anchors, setAnchors] = useState<any>({})
  const [info, warn, error] = useLogger()

  const handleClick = (index: number) => {
    return (e: any) => {
      setAnchors({ ...anchors, [index]: e.currentTarget })
    }
  }
  const handleClose = (index: number) => {
    setAnchors({ ...anchors, [index]: null })
  }

  return (
    <div className={style.connectionGroup}>
      <p>{name}</p>
      {connections.map((val, index: any) => {
        return (
          <div className={style.connectionItem} key={`${val.id}-conrow`}>
            <p>{val.name}</p>
            <div>
              <div>
                <Button
                  size={'small'}
                  endIcon={<KeyboardArrowDown />}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchors[index]) ? 'true' : undefined}
                  onClick={handleClick(index)}
                >
                  Assign
                </Button>
                <AssignMenu
                  groups={props.groups}
                  connection={val}
                  anchor={anchors[index]}
                  onClose={() => {
                    handleClose(index)
                  }}
                />
              </div>
              <Button
                onClick={() => {
                  info(`Disconnecting ${val.name} [${val.id}]`)
                  websocket.disconnectClient(val.id, (err?: Error) => {
                    if (err) {
                      error('Failed.', err.message)
                    } else {
                      info('Done!')
                    }
                  })
                  handleClose(val.id)
                }}
                size={'small'}
              >
                Disconnect
              </Button>
            </div>
          </div>
        )
      })}
    </div>
  )
}
