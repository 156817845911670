import AccountCircle from '@mui/icons-material/AccountCircle'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import { Button, Skeleton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useAuth, useInvitation, useOrganization } from '../../hooks/services'
import { Invitation } from '../../types/Invitation'
import User from '../../types/User'
import style from './organization.module.scss'

const gt = (user: 'member' | 'admin' | 'owner', target: 'member' | 'admin' | 'owner') => {
  if (user === 'owner') return true
  if (user === 'admin' && target === 'member') true
  return false
}

export const MemberCard: React.FC<any> = (props: {
  member?: { role: string; user: User }
  invite?: Invitation
  role: string
  org_id: string
  onRefresh: Function
  skeleton?: boolean
}) => {
  const { member, invite, skeleton } = props

  if (skeleton)
    return (
      <div className={style.member}>
        <Skeleton variant="circular" width={48} height={48} style={{ marginLeft: '15px' }} />
        <div className={style.memberName}>
          <Skeleton width={215} height={40} style={{ margin: '0', padding: 0 }} />
          <Skeleton width={215} height={24} style={{ marginTop: '-5px', padding: 0 }} />
        </div>
      </div>
    )

  const auth = useAuth()
  const org = useOrganization()
  const invitation = useInvitation()
  const { enqueueSnackbar } = useSnackbar()

  const canRemove =
    (!member || (member.user._id !== auth.getUser()._id && !(member.role === 'owner' || member.role === 'admin'))) &&
    (props.role === 'owner' || props.role === 'admin')

  if (member) {
    const canChange =
      member.user._id !== auth.getUser()._id &&
      (member.role === 'member' || member.role === 'admin') &&
      props.role === 'owner'
    const canRemove = member.user._id !== auth.getUser()._id && props.role === 'owner'

    const newRole = member.role === 'admin' ? 'member' : 'admin'

    return (
      <div className={style.member}>
        <AccountCircle style={{ fontSize: '48px', marginLeft: '15px' }} />
        <div className={style.memberName}>
          <p>{member.user.firstname + ' ' + member.user.lastname}</p>
          <p>{member.role[0].toUpperCase() + member.role.substring(1)}</p>
        </div>
        <div className={style.memberFunctions}>
          {canChange ? (
            <Button
              size="small"
              variant="text"
              onClick={() => {
                org
                  .updateRole(props.org_id, member.user._id, newRole)
                  .then((val) => {
                    enqueueSnackbar(`User role updated to ${newRole}!`, { variant: 'success' })
                    props.onRefresh()
                  })
                  .catch((err) => {
                    enqueueSnackbar('Failed to update user role.', { variant: 'error' })
                  })
              }}
            >
              {newRole === 'admin' ? 'Promote' : 'Demote'}
            </Button>
          ) : null}
          {canRemove ? (
            <Button
              size="small"
              variant="text"
              color="error"
              onClick={() => {
                org
                  .removeUser(props.org_id, member.user._id)
                  .then((val) => {
                    enqueueSnackbar('User removed!', { variant: 'success' })
                    props.onRefresh()
                  })
                  .catch((err) => {
                    enqueueSnackbar('Failed to remove user.', { variant: 'error' })
                  })
              }}
            >
              Remove
            </Button>
          ) : null}
        </div>
      </div>
    )
  }

  if (invite)
    return (
      <div className={style.member}>
        <ContactMailIcon style={{ fontSize: '48px', marginLeft: '15px' }} />
        <div className={style.memberName}>
          <p style={{ fontStyle: 'italic', color: '#444', fontSize: '18px' }}>{invite!.email}</p>
          <p>Invitation Sent</p>
        </div>
        <div className={style.memberFunctions}>
          {canRemove ? (
            <Button
              size="small"
              variant="text"
              color="error"
              onClick={() => {
                invitation
                  .deleteInvitation(invite._id)
                  .then((val) => {
                    enqueueSnackbar('Invitation removed!', { variant: 'success' })
                    props.onRefresh()
                  })
                  .catch((err) => {
                    enqueueSnackbar('Failed to remove invitation.', { variant: 'error' })
                  })
              }}
            >
              Remove
            </Button>
          ) : null}
        </div>
      </div>
    )

  return null
}
