import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useOrganization } from '../../hooks/services'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { CompactPicker } from 'react-color'
import style from './dashboard.module.scss'

const schema = yup
  .object({
    name: yup.string().min(2).required(),
  })
  .required()

export const CreateOrganization: React.FC<any> = (props: { open: boolean; onClose: (created: boolean) => void }) => {
  const organization = useOrganization()

  const { open, onClose } = props
  const [loading, setLoading] = useState(false)
  const [color, setColor] = useState('#4D4D4D')

  const { enqueueSnackbar } = useSnackbar()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onCreate = (data: any) => {
    setLoading(true)
    organization
      .createOrganization({ ...data, color })
      .then((val) => {
        enqueueSnackbar('Organization created!', { variant: 'success' })
        setLoading(false)
        onClose(true)
      })
      .catch((err) => {
        enqueueSnackbar('An error occurred.', { variant: 'error' })
        setLoading(false)
        onClose(false)
      })
  }

  return (
    <Dialog
      onClose={() => {
        setLoading(false)
        onClose(false)
      }}
      open={open}
      className={style.createOrg}
    >
      {loading ? <LinearProgress /> : null}
      <DialogTitle>Create Organization</DialogTitle>
      <form key="create-org-modal-form" onSubmit={handleSubmit(onCreate)}>
        <DialogContent>
          <TextField
            {...register('name', { required: true })}
            helperText={errors.name ? 'Invalid name.' : ''}
            error={Boolean(errors.name)}
            variant="outlined"
            label="Name"
            name="name"
            style={{ minWidth: '360px', marginBottom: '15px' }}
          />
          <CompactPicker
            color={color}
            onChangeComplete={(col) => {
              setColor(col.hex)
            }}
            styles={{
              default: {
                compact: {
                  width: '360px',
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" disabled={loading}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
