import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../hooks/services'
import { useTitle } from '../../hooks/title'
import style from './home.module.scss'
import logo from '../../assets/logo.png'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { version } from '../../../package.json'

function getEmailRegex() {
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
}

export const Home: React.FC = (props: any) => {
  const auth = useAuth()
  const [scroll, setScroll] = useState(window.scrollY)
  const elm = useRef<HTMLDivElement>(null)
  const [contact, setContact] = useState<any>({ name: '', email: '', subject: '', content: '', sent: false })
  const [loading, setLoading] = useState(false)

  useTitle('Home')

  return (
    <div className={style.root}>
      <div className={style.userBar}>
        <div>
          {auth.getUser().loggedIn ? (
            <>
              <p>Welcome, {auth.getUser().fullname.toUpperCase()}</p>
              <Link className={style.barLink} to="/dashboard">
                My Dashboard
              </Link>
              <Link className={style.barLink} to="/billing">
                Billing
              </Link>
            </>
          ) : null}
        </div>
        <div>
          {auth.getUser().loggedIn ? (
            <a
              className={style.barLink}
              onClick={() => {
                auth.logout()
                location.reload()
              }}
            >
              Logout
            </a>
          ) : (
            <Link className={style.barLink} to="/login">
              Register / Login
            </Link>
          )}
        </div>
      </div>

      <div
        ref={elm}
        className={style.content}
        onScroll={(e: any) => {
          setScroll(e.target.scrollTop)
        }}
      >
        {/* Change link underline limits */}
        <div className={style.navbar}>
          <div>
            <a
              data-underline={scroll < 500 ? 'true' : 'false'}
              className={style.barLink}
              onClick={() => {
                if (elm && elm.current) {
                  elm.current.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }
              }}
            >
              Home
            </a>
            <a
              data-underline={scroll >= 500 && scroll < 1600 ? 'true' : 'false'}
              className={style.barLink}
              onClick={() => {
                if (elm && elm.current) {
                  elm.current.scrollTo({
                    top: 650,
                    behavior: 'smooth',
                  })
                }
              }}
            >
              Features
            </a>
          </div>
          <img
            src={logo}
            onClick={() => {
              if (elm && elm.current) {
                elm.current.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              }
            }}
          />
          <div>
            <a
              data-underline={scroll >= 1600 && scroll < 2500 ? 'true' : 'false'}
              className={style.barLink}
              onClick={() => {
                if (elm && elm.current) {
                  elm.current.scrollTo({
                    top: 1700,
                    behavior: 'smooth',
                  })
                }
              }}
            >
              Pricing
            </a>
            <a
              data-underline={scroll >= 2500 ? 'true' : 'false'}
              className={style.barLink}
              onClick={() => {
                if (elm && elm.current) {
                  elm.current.scrollTo({
                    top: 3000,
                    behavior: 'smooth',
                  })
                }
              }}
            >
              Contact
            </a>
          </div>
        </div>

        <div className={style.heroImg}>
          <img src="https://placeholder.pics/svg/1920x650/DEDEDE/555555/Placeholder%20100VW x 650" />
        </div>

        <div className={style.halfLeft}>
          <div>
            <h2>Text here</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec,
              mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed
              eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit
              amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu
              diam. Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed felis sodales nec vulputate justo
              hendrerit. Vivamus varius pretium ligula, a aliquam odio euismod sit amet. Quisque laoreet sem sit amet
              orci ullamcorper at ultricies metus viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan,
              blandit sed diam.
            </p>
          </div>
          <div>
            <img src="https://placeholder.pics/svg/411x325/DEDEDE/555555/Placeholder%20411px x 325px" />
          </div>
        </div>

        <div className={style.halfRight}>
          <div>
            <img src="https://placeholder.pics/svg/411x325/DEDEDE/555555/Placeholder%20411px x 325px" />
          </div>
          <div>
            <h2>Text here</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec,
              mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed
              eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit
              amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu
              diam. Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed felis sodales nec vulputate justo
              hendrerit. Vivamus varius pretium ligula, a aliquam odio euismod sit amet. Quisque laoreet sem sit amet
              orci ullamcorper at ultricies metus viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan,
              blandit sed diam.
            </p>
          </div>
        </div>
        <div className={style.halfLeft}>
          <div>
            <h2>Text here</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec,
              mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed
              eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit
              amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu
              diam. Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed felis sodales nec vulputate justo
              hendrerit. Vivamus varius pretium ligula, a aliquam odio euismod sit amet. Quisque laoreet sem sit amet
              orci ullamcorper at ultricies metus viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan,
              blandit sed diam.
            </p>
          </div>

          <div>
            <img src="https://placeholder.pics/svg/411x325/DEDEDE/555555/Placeholder%20411px x 325px" />
          </div>
        </div>

        <div className={style.about}>
          <h1>What is **Name here**?</h1>
          <img src="https://placeholder.pics/svg/1920x1080/DEDEDE/555555/Placeholder%201920 x 1080" />
        </div>

        <div className={style.contact}>
          {loading ? (
            <div className={style.contactSuccess}>
              <CheckCircleIcon htmlColor="#00cc00" style={{ fontSize: '120px' }} />
              <p>Thanks! We will respond as soon as we can.</p>
            </div>
          ) : (
            <>
              <h1>Questions? Send us a message!</h1>
              <div className={style.field}>
                <p>Name</p>
                <input
                  type="text"
                  onChange={(e) => {
                    setContact({ ...contact, name: e.target.value })
                  }}
                  value={contact.name}
                  placeholder="Ben N"
                  disabled={loading}
                />
              </div>

              <div className={style.field}>
                <p>Email Address</p>
                <input
                  type="text"
                  onChange={(e) => {
                    setContact({ ...contact, email: e.target.value })
                  }}
                  value={contact.email}
                  placeholder="ben@example.com"
                  disabled={loading}
                />
              </div>

              <div className={style.field}>
                <p>Subject</p>
                <select
                  value={contact.subject}
                  onChange={(e) => {
                    setContact({ ...contact, subject: e.target.value })
                  }}
                  disabled={loading}
                >
                  <option value="">Select...</option>
                  <option value="GENERAL">General Question</option>
                  <option value="BILLING">Pricing / Billing</option>
                  <option value="TECH_SUPPORT">Technical Support</option>
                  <option value="OTHER">Other</option>
                </select>
              </div>
              <div className={style.field}>
                <p>Content</p>
                <textarea
                  value={contact.content}
                  onChange={(e) => {
                    setContact({ ...contact, content: e.target.value })
                  }}
                  disabled={loading}
                  draggable={false}
                  placeholder="Content of your message"
                ></textarea>
              </div>
              <div className={style.field}>
                <button
                  disabled={
                    contact.subject === '' ||
                    contact.name === '' ||
                    contact.email === '' ||
                    contact.content === '' ||
                    !getEmailRegex().test(contact.email) ||
                    loading
                  }
                  onClick={() => {
                    setLoading(true)
                    console.log('Submitting')
                  }}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>

        <div className={style.footer}>
          <p>v{version}</p>
          <p>Last Update: 4/23/2022</p>
        </div>
      </div>
    </div>
  )
}
