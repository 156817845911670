import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLogger } from '../../hooks/logger'
import { useTitle } from '../../hooks/title'
import { Organization } from '../../types/Organization'
import style from './billing.module.scss'
import { Unauthenticated } from '../login'
import { Navbar } from '../../components/navbar'
import { OrganizationCard } from '../../components/org-card'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useServices } from '../../hooks/services'
import CloseIcon from '@mui/icons-material/Close'
import { Server } from '../../types/Server'
import AlarmIcon from '@mui/icons-material/Alarm'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import StorageIcon from '@mui/icons-material/Storage'

/**
 * TODO:
 *
 * + Add a Billing page
 *   - Allow the users to view end subscription date, cancel for each server
 *
 * ** MULTIPLY TIMESTAMPS BY 1000 (unix seconds -> javascript milliseconds)
 */
export const Billing: React.FC<any> = () => {
  const { auth, organization, invitation, stripe } = useServices()

  const [orgs, setOrgs] = useState<string[]>([])
  const [servers, setServers] = useState<Server[]>([])
  const [info, warn, error] = useLogger()
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  useTitle('Billing')

  useEffect(() => {
    if (!auth.getUser().loggedIn || auth.getToken().length === 0) {
      enqueueSnackbar('Not logged in.', { variant: 'error' })
      Unauthenticated('/billing', navigate)
      return
    }
    stripe
      .getSubscriptions()
      .then((servs: Server[]) => {
        console.log(servs)
        for (let i = 0; i < servs.length; i++)
          if (!orgs.includes(servs[i].organization_id)) setOrgs([...orgs, servs[i].organization_id])
        setServers(servs)
        setLoading(false)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          enqueueSnackbar('Not logged in.', { variant: 'error' })
          Unauthenticated('/billing', navigate)
          return
        }
        console.log(err)
        enqueueSnackbar('Backend offline.', { variant: 'error' })
        error(err)
        setLoading(false)
      })
  }, [refresh])

  const [cancel, setCancel] = useState<Server | null>(null)
  const [deleting, setDeleting] = useState<boolean>(false)

  const intervalName: any = {
    month: 'mo',
    year: 'yr',
  }

  return (
    <div className={style.billing}>
      {/* Navbar */}
      <Navbar
        back={() => {
          navigate('/dashboard')
        }}
        onRefresh={() => setRefresh(!refresh)}
        title={'Billing'}
        auth={auth}
        invitation={invitation}
      />
      {/* Loading bar */}
      {loading ? <LinearProgress color="secondary" /> : null}

      {/* Popups */}
      <Dialog open={cancel !== null} onClose={() => setCancel(null)}>
        {cancel === null ? null : (
          <>
            {deleting ? <LinearProgress color="error" /> : null}
            <DialogTitle>Delete {cancel.name}?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Your server will be deleted at the end of the subscription period (
                {new Date(cancel.subscription!.current_period_end * 1000).toLocaleDateString()}). Do you wish to
                continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCancel(null)}>Cancel</Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setDeleting(true)
                  stripe
                    .cancel(cancel.stripe_subscription_id)
                    .then((val) => {
                      enqueueSnackbar('Subscription cancelled!', { variant: 'success' })
                      setDeleting(false)
                      setCancel(null)
                      setRefresh(!refresh)
                    })
                    .catch((err) => {
                      enqueueSnackbar('Failed to cancel subscription.', { variant: 'error' })
                      setDeleting(false)
                      setCancel(null)
                      setRefresh(!refresh)
                    })
                }}
              >
                End Subscription
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Main Content */}
      <div>
        <p style={{ userSelect: 'none' }}>Active Subscriptions</p>
        <div className={style.billingContainer}>
          {servers.filter((x) => x.subscription!.status === 'active').length === 0 ? (
            <p
              style={
                loading
                  ? { display: 'none' }
                  : { width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginTop: '40px' }
              }
            >
              No active subscriptions found.
            </p>
          ) : (
            <>
              {orgs.map((val, index) => {
                if (
                  servers.filter((x) => x.organization_id === val && x.subscription!.status === 'active').length > 0
                ) {
                  const orgServers = servers.filter(
                    (x) => x.organization_id === val && x.subscription!.status === 'active',
                  )
                  return (
                    <Accordion key={`billing-active-${val}`}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ borderBottom: '1px solid #3338' }}>
                        <Typography>
                          <CorporateFareIcon style={{ verticalAlign: 'sub', marginRight: '10px' }} />
                          {(orgServers[0] as any).organization.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ paddingTop: '0', paddingBottom: '0' }}>
                        {orgServers.map((val) => {
                          return (
                            <div className={style.server} key={`billing-server-${val._id}`}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <StorageIcon />
                                <p
                                  style={{
                                    lineHeight: '24px',
                                    verticalAlign: 'center',
                                    marginLeft: '5px',
                                  }}
                                >
                                  {val.name} - {val.plan} Plan
                                </p>
                                <p style={{ lineHeight: '24px', marginLeft: 'auto' }}>
                                  ${val.subscription!.plan.amount / 100} /{' '}
                                  {val.subscription!.plan.interval_count === 1
                                    ? null
                                    : val.subscription!.plan.interval_count}
                                  {val.subscription!.plan.interval}
                                </p>
                              </div>
                              <div className={style.statRow}>
                                <div className={style.stat}>
                                  <p>Start Date</p>
                                  <p>{new Date(val.subscription!.start_date * 1000).toLocaleDateString()}</p>
                                </div>
                                <div className={style.stat}>
                                  <p>Current Period</p>
                                  <p>
                                    {new Date(val.subscription!.current_period_start * 1000).toLocaleDateString()} -{' '}
                                    {new Date(val.subscription!.current_period_end * 1000).toLocaleDateString()}
                                  </p>
                                </div>
                                <div className={style.stat}>
                                  <p>Interval</p>
                                  <p>
                                    {val.subscription!.plan.interval_count} {val.subscription!.plan.interval}
                                    {val.subscription!.plan.interval_count > 1 ? 's' : ''}
                                  </p>
                                </div>
                                <div className={style.stat}>
                                  <p>Price</p>
                                  <p>${val.subscription!.plan.amount / 100}</p>
                                </div>
                                <div className={style.stat}>
                                  <p>Will Renew?</p>
                                  <p>{val.subscription!.cancel_at_period_end ? 'No' : 'Yes'}</p>
                                </div>
                                <div className={style.stat}>
                                  <p>Status</p>
                                  <p>{String(val.subscription!.status).toUpperCase()}</p>
                                </div>
                              </div>
                              {val.subscription!.cancel_at_period_end ? (
                                <p className={style.error}>
                                  This server is scheduled for deletion on{' '}
                                  {new Date(val.subscription!.current_period_end * 1000).toLocaleDateString()} at{' '}
                                  {new Date(val.subscription!.current_period_end * 1000).toLocaleTimeString()}.
                                </p>
                              ) : null}
                              <Button
                                variant="contained"
                                color="error"
                                style={{ width: '100px', marginTop: '10px', marginBottom: '15px' }}
                                disabled={val.subscription!.cancel_at_period_end}
                                onClick={() => setCancel(val)}
                              >
                                Delete
                              </Button>
                            </div>
                          )
                        })}
                      </AccordionDetails>
                    </Accordion>
                  )
                }
                return null
              })}
            </>
          )}
        </div>
        <p style={{ userSelect: 'none' }}>Past Subscriptions</p>
        <div className={style.billingContainer}>
          {servers.filter((x) => x.subscription!.status !== 'active').length === 0 ? (
            <p
              style={
                loading
                  ? { display: 'none' }
                  : { width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginTop: '40px' }
              }
            >
              No past subscriptions found.
            </p>
          ) : (
            <>
              {orgs.map((val, index) => {
                if (
                  servers.filter((x) => x.organization_id === val && x.subscription!.status !== 'active').length > 0
                ) {
                  const orgServers = servers.filter(
                    (x) => x.organization_id === val && x.subscription!.status !== 'active',
                  )
                  return (
                    <Accordion key={`billing-active-${val}`}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ borderBottom: '1px solid #3338' }}>
                        <Typography>
                          <CorporateFareIcon style={{ verticalAlign: 'sub', marginRight: '10px' }} />
                          {(orgServers[0] as any).organization.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ paddingTop: '0', paddingBottom: '0' }}>
                        {orgServers.map((val) => {
                          return (
                            <div
                              className={style.server}
                              key={`billing-server-${val._id}`}
                              style={{ paddingBottom: '15px' }}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <StorageIcon />
                                <p
                                  style={{
                                    lineHeight: '24px',
                                    verticalAlign: 'center',
                                    marginLeft: '5px',
                                  }}
                                >
                                  {val.name} - {val.plan} Plan
                                </p>
                                <p style={{ lineHeight: '24px', marginLeft: 'auto' }}>
                                  ${val.subscription!.plan.amount / 100} /{' '}
                                  {val.subscription!.plan.interval_count === 1
                                    ? null
                                    : val.subscription!.plan.interval_count}
                                  {val.subscription!.plan.interval}
                                </p>
                              </div>
                              <div className={style.statRow}>
                                <div className={style.stat}>
                                  <p>Start Date</p>
                                  <p>{new Date(val.subscription!.start_date * 1000).toLocaleDateString()}</p>
                                </div>
                                <div className={style.stat}>
                                  <p>Cancelation Date</p>
                                  <p>{new Date(val.subscription!.ended_at * 1000).toLocaleDateString()}</p>
                                </div>
                                <div className={style.stat}>
                                  <p>Interval</p>
                                  <p>
                                    {val.subscription!.plan.interval_count} {val.subscription!.plan.interval}
                                    {val.subscription!.plan.interval_count > 1 ? 's' : ''}
                                  </p>
                                </div>
                                <div className={style.stat}>
                                  <p>Price</p>
                                  <p>${val.subscription!.plan.amount / 100}</p>
                                </div>
                                <div className={style.stat}>
                                  <p>Status</p>
                                  <p>{String(val.subscription!.status).toUpperCase()}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </AccordionDetails>
                    </Accordion>
                  )
                }
                return null
              })}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
