import { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { set as LSSet } from 'local-storage'
import { useTitle } from '../../hooks/title'
import { Unauthenticated } from '../login'
import { Navbar } from '../../components/navbar'
import { useSnackbar } from 'notistack'
import { Organization as IOrg } from '../../types/Organization'
import { useAuth, useOrganization, useServer, useStripe } from '../../hooks/services'
import style from './organization.module.scss'
import { Button, LinearProgress, Skeleton } from '@mui/material'
import CorporateFare from '@mui/icons-material/CorporateFare'
import { ServerCard } from './server-card'
import { MemberCard } from './member-card'
import User from '../../types/User'
import { Invite } from './invite'
import { useRefresh } from '../../hooks/refresh'
import { Teams } from './teams'
import { NotFound } from '../not-found'

export const Organization: React.FC<any> = () => {
  const organization = useOrganization()
  const auth = useAuth()
  const server = useServer()
  const stripe = useStripe()
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(true)
  const [org, setOrg] = useState<IOrg | null>(null)
  const [inviteOpen, setInviteOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  useTitle(org === null ? 'Organization' : org.name)

  useRefresh(() => {
    if (!auth.getUser().loggedIn || auth.getToken().length === 0) {
      enqueueSnackbar('Not logged in.', { variant: 'error' })
      LSSet('postlogin', location.pathname)
      Unauthenticated('/dashboard', navigate)
      return
    }

    if (!id) {
      navigate('/dashboard')
      return
    }

    organization
      .getOrganization(id, { servers: true, invitations: true, members: true, platforms: true })
      .then((val) => {
        setOrg(val)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        enqueueSnackbar('Failed to get organization.', { variant: 'error' })
        navigate('/dashboard', { replace: true })
      })
  }, 15000)

  useEffect(() => {
    if (!id) {
      navigate('/dashboard')
      return
    }
    organization
      .getOrganization(id, { servers: true, invitations: true, members: true, platforms: true })
      .then((val) => {
        setOrg(val)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        enqueueSnackbar('Failed to get organization.', { variant: 'error' })
        navigate('/dashboard', { replace: true })
      })
  }, [refresh])

  const role = org === null ? 'member' : org.members.find((x) => (x.user as User)._id === auth.getUser()._id)?.role

  return (
    <Routes>
      <Route path="/teams" element={<Teams />} />
      <Route
        path="/"
        element={
          <div>
            <Navbar
              back={() => {
                navigate('/dashboard')
              }}
              title={org === null ? 'Organization' : `Organization - ${org.name}`}
              onRefresh={() => setRefresh(!refresh)}
              org={id}
            />
            {loading ? <LinearProgress color="secondary" /> : null}

            <div className={style.org}>
              {org === null ? (
                <Skeleton
                  variant="rectangular"
                  width={210}
                  height={35}
                  style={{ marginTop: '50px', marginLeft: '25px' }}
                />
              ) : (
                <p>
                  <CorporateFare fontSize="large" /> {org.name}
                </p>
              )}

              <p>Servers</p>
              {org === null || role === 'member' ? null : (
                <Button
                  style={{ marginLeft: '25px', marginTop: '10px' }}
                  variant="contained"
                  onClick={() => {
                    // CHANGE THIS TO BE A POPUP
                    stripe.checkout('SERVER_STARTER', org._id)
                  }}
                >
                  Deploy Server
                </Button>
              )}
              <div className={style.serverRow}>
                {org === null ? (
                  <>
                    <ServerCard skeleton={true} />
                  </>
                ) : (
                  <>
                    {org.servers!.map((val) => {
                      return (
                        <ServerCard
                          key={`server-${val._id}-${val.instance ? 'yes' : 'no'}-${
                            val.instance ? val.instance.PublicIpAddress : 'no'
                          }-${val.instance ? val.instance.State?.Name : 'no'}`}
                          server={val}
                          refresh={() => {
                            setRefresh(!refresh)
                          }}
                        />
                      )
                    })}
                  </>
                )}
              </div>
              {/* <p>Platforms</p> Ignore platforms for now, implementation for that will come at a later date. */}
              <p>Members</p>
              {org === null || role === 'member' ? null : (
                <Button
                  style={{ marginLeft: '25px', marginTop: '10px' }}
                  variant="contained"
                  onClick={() => setInviteOpen(true)}
                >
                  + Invite User
                </Button>
              )}
              <div className={style.memberRow}>
                {org === null ? (
                  <>
                    <MemberCard skeleton={true} />
                  </>
                ) : (
                  <>
                    {org.members.map((val) => {
                      return (
                        <MemberCard
                          key={`member-${(val.user as User)._id}`}
                          member={val}
                          role={role}
                          org_id={org._id}
                          onRefresh={() => {
                            setRefresh(!refresh)
                          }}
                        />
                      )
                    })}
                    {org.invitations!.map((val) => {
                      return (
                        <MemberCard
                          key={`member-invite-${val._id}`}
                          invite={val}
                          role={role}
                          org_id={org._id}
                          onRefresh={() => {
                            setRefresh(!refresh)
                          }}
                        />
                      )
                    })}
                  </>
                )}
              </div>

              {org === null ? null : (
                <Invite
                  org={org}
                  open={inviteOpen}
                  onClose={() => {
                    setInviteOpen(false)
                    setRefresh(!refresh)
                  }}
                />
              )}
            </div>
          </div>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
