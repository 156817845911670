import Check from '@mui/icons-material/Check'
import { CircularProgress, Divider, InputAdornment, Menu, MenuItem, TextField } from '@mui/material'
import { useState } from 'react'
import { useLogger } from '../hooks/logger'
import { useWebsocket } from '../hooks/services'

const removeDupes = (lst: any[]) => {
  const newList: any[] = []
  for (let i = 0; i < lst.length; i++) if (!newList.includes(lst[i])) newList.push(lst[i])
  return newList
}

export const AssignMenu: React.FC<any> = (props: {
  groups: string[]
  connection: any
  anchor: any
  onClose: () => void
}) => {
  const { groups, anchor, connection, onClose } = props
  const [info, warn, error] = useLogger()
  const websocket = useWebsocket()
  const [val, setVal] = useState('')
  const [loading, setLoading] = useState(false)
  const [succ, setSucc] = useState(false)

  return (
    <Menu
      elevation={0}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={() => onClose()}
      PaperProps={{
        style: {
          width: 340,
          maxHeight: 48 * 5.5,
          boxShadow: '1px 1px 5px black',
        },
      }}
    >
      <div style={{ padding: '10px' }}>
        <p style={{ fontWeight: 400, marginBottom: '10px' }}>Create new group</p>
        <TextField
          fullWidth
          value={val}
          disabled={loading}
          style={succ ? { borderColor: 'green' } : {}}
          placeholder="Group Name"
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => {
            setVal(e.target.value)
            setSucc(false)
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter' && val.length > 0) {
              setLoading(true)
              info(`Assigning ${connection.name} [${connection.id}] to ${val}`)
              websocket.assign(connection.id, val, (err?: Error) => {
                if (err) {
                  error('Failed.', err.message)
                } else {
                  info('Done!')
                  setSucc(true)
                }
                setLoading(false)
              })
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ marginRight: '1px' }}>
                {loading ? (
                  <CircularProgress size={24} />
                ) : val.length > 0 ? (
                  <Check
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setLoading(true)
                      info(`Assigning ${connection.name} [${connection.id}] to ${val}`)
                      websocket.assign(connection.id, val, (err?: Error) => {
                        if (err) {
                          error('Failed.', err.message)
                        } else {
                          setSucc(true)
                          info('Done!')
                        }
                        setLoading(false)
                      })
                    }}
                  />
                ) : null}
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Divider style={{ marginBottom: '10px' }} />
      <p style={{ margin: '10px', fontWeight: 400 }}>Join existing group</p>
      {removeDupes([
        'Group 1',
        'Group 2',
        'Group 3',
        'Group 4',
        'Group 5',
        'Group 6',
        'Group 7',
        'Group 8',
        'Group 9',
        'Group 10',
        ...props.groups,
      ]).map((group, index) => {
        return (
          <MenuItem
            autoFocus={false}
            key={`${connection.id}-${group}-ind${index}`}
            selected={connection.group_id === group}
            onClick={() => {
              setLoading(true)
              info(`Assigning ${connection.name} [${connection.id}] to ${group}`)
              websocket.assign(connection.id, group, (err?: Error) => {
                if (err) {
                  error('Failed.', err.message)
                } else {
                  info('Done!')
                }
                setLoading(false)
              })
            }}
          >
            {group}
          </MenuItem>
        )
      })}
    </Menu>
  )
}
