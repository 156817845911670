import axios, { AxiosResponse } from 'axios'
import { Server } from '../types/Server'
import { AuthService } from './auth.service'

const PRICES: any = {
  SERVER_STARTER:
    process.env.NODE_ENV === 'development' ? 'price_1L3QInEHzSiGPRsUCybOcn53' : 'price_1L3QdJEHzSiGPRsUta3BTOQj',
  SERVER_PREMIUM:
    process.env.NODE_ENV === 'development' ? 'price_1L3Qf3EHzSiGPRsUi5AHDcn9' : 'price_1L3QdoEHzSiGPRsU3jgiMjEr',
  SERVER_DELUXE:
    process.env.NODE_ENV === 'development' ? 'price_1L3QfNEHzSiGPRsUTwvduW6G' : 'price_1L3QeOEHzSiGPRsUWeWrAyzf',
}

export class StripeService {
  constructor(private auth: AuthService) {}

  checkout = (price: string, organization_id: string): Promise<any> => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/stripe/create-checkout-session`,
      data: {
        price: PRICES[price],
        organization_id,
      },
      headers: {
        Authorization: this.auth.getToken(),
      },
      maxRedirects: 0,
    })
      .then((res: any) => {
        window.location = res.data.redirect
      })
      .catch((err) => {
        console.log(err)
        alert(err)
      })
  }

  cancel = (subscription: string): Promise<any> => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/stripe/cancel`,
      data: {
        subscription_id: subscription,
      },
      headers: {
        Authorization: this.auth.getToken(),
      },
    })
  }

  getSubscriptions = (): Promise<Server[]> => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/v1/stripe/subscriptions`,
      headers: {
        Authorization: this.auth.getToken(),
      },
    }).then((val) => {
      return val.data as Server[]
    })
  }
}
