import style from './avatar-group.module.scss'
import React, { useState } from 'react'
import { Avatar } from './avatar'
import { Tooltip } from '@mui/material'

/* 

  AvatarGroup:
    - Eventually we will pass in user objects instead
      of urls/_ids. It is currently structured with
      urls/_ids for designing only.

*/
export const AvatarGroup: React.FC<any> = (props: {
  urls: string[]
  _ids: string[]
  size: 'SMALL' | 'MEDIUM' | 'LARGE'
}) => {
  return (
    <div data-size={props.size} className={style.avatarGroup}>
      {props.urls.map((val, index) => {
        return (
          <Avatar
            size={props.size}
            key={`avatargroup-${props._ids[index]}-${index}`}
            url={val}
            _id={props._ids[index]}
            tooltip={true}
          />
        )
      })}
    </div>
  )
}
