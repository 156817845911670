import style from './scene-controller.module.scss'
import { IOverlay, WebsocketService } from '../services'
import { Button, Grid, Input, Slider } from '@mui/material'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useWebsocket } from '../hooks/services'

export const SceneController: React.FC<any> = (props: { overlay: IOverlay }) => {
  const { overlay } = props
  const websocket = useWebsocket()
  const [scenes, setScenes] = useState<any[]>(props.overlay.scenes)

  const visibilityChange = (name: string, state: boolean, transition: boolean, delay: number) => {
    setTimeout(() => {
      // Fix
      if (websocket.loggedIn) websocket.io.send('scene:visibility', overlay.group_id, { name, state, transition })
    }, delay * 1000)
  }

  return (
    <div className={style.controller}>
      {overlay.scenes.length === 0 ? (
        <p>No scenes found.</p>
      ) : (
        <>
          {scenes.map((val: any, index: number) => {
            return (
              <div key={`scenecontroller-${val.name}-${index}`}>
                <p>{val.name}</p>
                <div className={style.flexRow}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      visibilityChange(val.name, true, true, val.delay ?? 0)
                    }}
                  >
                    Transition In
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      visibilityChange(val.name, false, true, val.delay ?? 0)
                    }}
                  >
                    Transition Out
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      visibilityChange(val.name, true, false, val.delay ?? 0)
                    }}
                  >
                    Show
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      visibilityChange(val.name, false, false, val.delay ?? 0)
                    }}
                  >
                    Hide
                  </Button>
                </div>
                <div className={`${style.flexRow} ${style.noMargin}`}>
                  <DelayController
                    onChange={(val: number) => {
                      const scs = [...scenes]
                      scs[index].delay = val
                      setScenes(scs)
                    }}
                  />
                </div>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

const DelayController: React.FC<any> = (props: { onChange: Function }) => {
  const [value, setValue] = useState(0)
  return (
    <Grid sx={{ width: 350 }} container spacing={2} alignItems="center">
      <Grid item>
        <p>Delay (s)</p>
      </Grid>
      <Grid item xs>
        <Slider
          step={0.1}
          min={0}
          max={30}
          value={value}
          onChange={(e: any, value) => {
            setValue(value as number)
          }}
          onChangeCommitted={(e: any, value) => props.onChange(value as number)}
          aria-labelledby="input-slider"
        />
      </Grid>
      <Grid item>
        <Input
          value={value}
          size="small"
          onChange={(e) => {
            setValue(e.target.value === '' ? 0 : Number(e.target.value))
            props.onChange(e.target.value === '' ? 0 : Number(e.target.value))
          }}
          onBlur={() => {
            if (value < 0) {
              setValue(0)
              props.onChange(0)
            } else if (value > 100) {
              setValue(100)
              props.onChange(100)
            }
          }}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
        />
      </Grid>
    </Grid>
  )
}
