import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useRefresh } from '../../hooks/refresh'
import { useOrganization, useAuth, useServer, useTeam } from '../../hooks/services'
import { useTitle } from '../../hooks/title'
import { Unauthenticated } from '../login'
import { Organization as IOrg } from '../../types/Organization'
import { set as LSSet } from 'local-storage'
import User from '../../types/User'
import { LinearProgress } from '@mui/material'
import { Navbar } from '../../components/navbar'
import { Team } from '../../types/Team'
import style from './organization.module.scss'
import { TeamCard } from '../../components/team-card'

/**
 *  PAGINATE THIS PAGE (allow user to select 25, 50 [default], 100)
 */
export const Teams: React.FC<any> = (props: any) => {
  const organization = useOrganization()
  const team = useTeam()
  const auth = useAuth()
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(true)
  const [org, setOrg] = useState<IOrg | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [teams, setTeams] = useState<{ page: number; data: Team[] }[]>([])
  const [status, setStatus] = useState<{ page: number; limit: number }>({ page: 1, limit: 50 })
  useTitle(org === null ? 'Organization' : org.name)

  const fetchTeams = (page: number, limit: number = 50) => {
    if (id)
      team
        .getTeams(id, page, limit)
        .then((val) => {
          const curTeams = teams.filter((x) => x.page !== page)
          curTeams.push({ page, data: val.data })
          setTeams(curTeams)
          setLoading(false)
          if (page > status.page) setStatus({ ...status, page })
        })
        .catch((err) => {
          setLoading(false)
        })
  }

  const refreshTeams = () => {
    for (let i = 1; i <= status.page; i++) {
      fetchTeams(status.page, status.limit)
    }
  }

  useRefresh(() => {
    if (!auth.getUser().loggedIn || auth.getToken().length === 0) {
      enqueueSnackbar('Not logged in.', { variant: 'error' })
      LSSet('postlogin', location.pathname)
      Unauthenticated('/dashboard', navigate)
      return
    }

    if (!id) {
      navigate('/dashboard')
      return
    }

    organization
      .getOrganization(id)
      .then((val) => {
        setOrg(val)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        enqueueSnackbar('Failed to get organization.', { variant: 'error' })
        navigate('/dashboard', { replace: true })
      })

    refreshTeams()
  }, 60 * 1000)

  useEffect(() => {
    fetchTeams(1)
  }, [true])

  useEffect(() => {
    refreshTeams()
  }, [refresh])

  const role = org === null ? 'member' : org.members.find((x) => (x.user as User)._id === auth.getUser()._id)?.role

  return (
    <div>
      <Navbar
        back={() => {
          navigate(`/organization/${id}`)
        }}
        title={org === null ? 'Organization' : `Organization - ${org.name}`}
        onRefresh={() => setRefresh(!refresh)}
        org={id}
      />
      {loading ? <LinearProgress color="secondary" /> : null}
      <div className={style.teamPage}>
        <p style={{ marginLeft: '120px' }}>Teams</p>
        <div className={style.teams}>
          {teams.length === 1 && !loading && teams[0].data.length === 0 ? (
            <p>No teams found.</p>
          ) : (
            teams.map((val) => {
              return val.data.map((val) => {
                return (
                  <TeamCard
                    key={`org-team-${val._id}-${val.updatedAt.toString()}`}
                    avatar={val.avatar}
                    _id={val._id}
                    name={val.name}
                    colors={val.colors}
                    roster={val.roster.map((pl) => pl.name)}
                    onDelete={(id: string) => {
                      team
                        .deleteTeam(id)
                        .then((val) => {
                          setRefresh(!refresh)
                          enqueueSnackbar('Team deleted!', { variant: 'success' })
                        })
                        .catch((err) => {
                          enqueueSnackbar('Failed to delete team.', { variant: 'error' })
                        })
                    }}
                    onEdit={(id: string) => {
                      console.log(`edit ${id}`)
                    }}
                  />
                )
              })
            })
          )}
        </div>
      </div>
    </div>
  )
}
