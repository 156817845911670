import { Button, Skeleton } from '@mui/material'
import { Server } from '../../types/Server'
import style from './organization.module.scss'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { timeSince } from '../../util/date'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth, useServer } from '../../hooks/services'
import { useSnackbar } from 'notistack'
import { useRefresh } from '../../hooks/refresh'

export const ServerCard: React.FC<any> = (props: { server: Server; skeleton?: boolean; refresh: Function }) => {
  const { skeleton } = props
  const serverService = useServer()
  const auth = useAuth()
  const [name, setName] = useState<string | null>(null)
  const [connecting, setConnecting] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [server, setServer] = useState<Server>(props.server)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const updateName = () => {
    if (!name || !server) return
    setLoading(true)
    serverService
      .updateServer(
        server._id,
        {
          name,
        },
        true,
      )
      .then((val) => {
        setTimeout(() => {
          enqueueSnackbar('Server updated!', { variant: 'success' })
          setLoading(false)
          setName(null)
          props.refresh()
        }, 1000)
      })
      .catch((err) => {
        setLoading(false)
        setName(null)
        enqueueSnackbar('Failed to update server.', { variant: 'error' })
      })
  }

  if (skeleton)
    return (
      <div className={style.serverCard}>
        <div className={style.serverSkeleton}>
          <Skeleton variant="rectangular" width={265} height={50} style={{ marginTop: '10px', maxHeight: '50px' }} />
          <Skeleton variant="rectangular" width={265} height={105} style={{ marginTop: '10px', maxHeight: '175px' }} />
          <Skeleton variant="rectangular" width={265} height={50} style={{ marginTop: '10px', maxHeight: '50px' }} />
          <Skeleton variant="rectangular" width={265} height={50} style={{ marginTop: '10px', maxHeight: '50px' }} />
        </div>
      </div>
    )

  useRefresh(() => {
    setRefresh(!refresh)
  }, 500)

  if (server === null) return null

  return (
    <div className={style.serverCard}>
      <div style={{ backgroundColor: server?.color ?? '#1976d2' }}></div>
      <div>
        <div className={style.name}>
          {name === null ? (
            <p style={{ fontWeight: 400 }}>{server.name ?? 'Unnamed Server'}</p>
          ) : (
            <input
              type="text"
              placeholder={server.name ?? 'Unnamed Server'}
              onChange={(e) => {
                setName(e.target.value)
              }}
              disabled={loading}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  updateName()
                }
              }}
            />
          )}
          {server.instance_id === '' || server.instance!.State!.Name?.toUpperCase() === 'TERMINATED' ? null : name ===
            null ? (
            <EditIcon
              onClick={() => {
                setName(server.name ?? 'Unnamed Server')
              }}
            />
          ) : (
            <CheckIcon
              onClick={() => {
                if (!loading) updateName()
              }}
            />
          )}
        </div>
        {server.instance_id === '' ? (
          <p style={{ marginTop: '5px' }}>Deleted on {new Date(server.updatedAt).toLocaleDateString()}</p>
        ) : !server.instance ? null : (
          <>
            <div className={style.row}>
              <p>Status</p>
              <p>{server.instance!.State!.Name?.toUpperCase()}</p>
            </div>
            <div className={style.row}>
              <p>Address</p>
              <p>{server.instance!.PublicIpAddress ?? '-'}</p>
            </div>
            <div className={style.row}>
              <p>Last Launch</p>
              <p>{timeSince(server.instance!.LaunchTime!)} ago</p>
            </div>

            {!server.instance!.PublicIpAddress ? null : (
              <Button
                fullWidth
                variant="outlined"
                disabled={!server.instance!.PublicIpAddress || server.instance!.State!.Name !== 'running'}
                onClick={() => {
                  window.open(`${window.origin}/server/${server._id}`)
                }}
              >
                Control Board <OpenInNewIcon style={{ marginLeft: '5px', fontSize: '20px' }} />
              </Button>
            )}

            {!server.instance!.PublicIpAddress ? null : (
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  // Connect to server via RCON.
                  setConnecting(true)

                  const RCON = new WebSocket(`ws://localhost:8477`)

                  RCON.onopen = () => {
                    console.log('opened')
                    RCON.send(`connect https://${server._id}.nylund.us ${auth.getToken()}`)
                  }

                  RCON.onmessage = (msg) => {
                    console.log(`msg: ${msg.data}`)
                    if (msg.data === `CONNECT https://${server._id}.nylund.us`) {
                      enqueueSnackbar('Sent RL connect command!', { variant: 'success' })
                      setConnecting(false)
                      RCON.close()
                    }
                  }

                  RCON.onerror = (err) => {
                    console.log('failed', err)
                    enqueueSnackbar('Failed to connect. Is bakkesmod enabled?', { variant: 'error' })
                    setConnecting(false)
                  }

                  RCON.onclose = () => {
                    console.log('closed')
                    setConnecting(false)
                  }
                }}
                disabled={server.instance!.State!.Code === 64 || server.instance!.State!.Code === 0 || connecting}
              >
                Connect
              </Button>
            )}
            {server.instance!.PublicIpAddress ? null : (
              <Button
                fullWidth
                variant="contained"
                color="success"
                onClick={() => {
                  serverService
                    .sendCommand(server._id, 'start')
                    .then((val) => {
                      enqueueSnackbar('Sent start command!', { variant: 'success' })
                      //props.refresh()
                    })
                    .catch((err) => {
                      enqueueSnackbar('Failed to send start command.', { variant: 'error' })
                      //props.refresh()
                    })
                }}
                disabled={
                  server.instance!.State!.Code === 0 || server.instance!.State!.Name?.toUpperCase() === 'TERMINATED'
                }
              >
                Start
              </Button>
            )}
            {!server.instance!.PublicIpAddress ? null : (
              <Button
                fullWidth
                variant="contained"
                color="error"
                disabled={server.instance!.State!.Code === 64 || server.instance!.State!.Code === 0}
                onClick={() => {
                  serverService
                    .sendCommand(server._id, 'stop')
                    .then((val) => {
                      enqueueSnackbar('Sent stop command!', { variant: 'success' })
                      //props.refresh()
                    })
                    .catch((err) => {
                      enqueueSnackbar('Failed to send stop command.', { variant: 'error' })
                      //props.refresh()
                    })
                }}
              >
                Shutdown
              </Button>
            )}
            {/*
          <Button
            fullWidth
            variant="outlined"
            color="error"
            disabled={server.instance!.State!.Name === 'stopping'}
            onClick={() => {
              serverService
                .deleteServer(server._id)
                .then((val) => {
                  enqueueSnackbar('Server deleted!', { variant: 'success' })
                  props.refresh()
                })
                .catch((err) => {
                  enqueueSnackbar('Failed to delete server.', { variant: 'error' })
                })
            }}
          >
            Delete
          </Button>
          */}
          </>
        )}
      </div>
    </div>
  )
}
