import style from './team-card.module.scss'
import { Paper, Divider, Button } from '@mui/material'
import React, { useState } from 'react'

// Props will need to be changed
export const TeamCard: React.FC<any> = (props: {
  avatar: string
  _id: string
  name: string
  colors: { primary: string; secondary: string }
  roster: string[]
  onEdit: (id: string) => void
  onDelete: (id: string) => void
}) => {
  return (
    <Paper elevation={4} className={style.card}>
      <img src={props.avatar} style={{ filter: `drop-shadow(1px 1px 3px ${props.colors.secondary})` }} />
      <h2>{props.name}</h2>
      <Divider />
      <div className={style.roster}>
        {props.roster.map((val, index) => {
          return (
            <p key={`teamroster-${index}-${val}`} style={{ borderColor: props.colors.primary }}>
              {val}
            </p>
          )
        })}
      </div>

      {/* maybe remove onEdit and link it to a new page? */}
      <Button variant="outlined" onClick={() => props.onEdit(props._id)}>
        Edit Team
      </Button>
      <Button variant="contained" color="error" onClick={() => props.onDelete(props._id)}>
        Remove Team
      </Button>
    </Paper>
  )
}
