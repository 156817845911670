import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLogger } from '../../hooks/logger'
import { useTitle } from '../../hooks/title'
import { Organization } from '../../types/Organization'
import style from './dashboard.module.scss'
import { Unauthenticated } from '../login'
import { Navbar } from '../../components/navbar'
import { OrganizationCard } from '../../components/org-card'
import { LinearProgress } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useServices } from '../../hooks/services'
import { CreateOrganization } from './create-org'
import CloseIcon from '@mui/icons-material/Close'

/**
 * TODO:
 *
 * + Add a Billing page
 *   - Allow users to purchase a relay for a specified organization
 *   - Allow the users to view end subscription date, cancel
 */
export const Dashboard: React.FC<any> = () => {
  const { auth, organization, invitation } = useServices()

  const [orgs, setOrgs] = useState<Organization[]>([])
  const [info, warn, error] = useLogger()
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  useTitle('Dashboard')

  useEffect(() => {
    if (!auth.getUser().loggedIn || auth.getToken().length === 0) {
      enqueueSnackbar('Not logged in.', { variant: 'error' })
      Unauthenticated('/dashboard', navigate)
      return
    }
    organization
      .getOrganizations()
      .then((orgs: Organization[]) => {
        setOrgs(orgs)
        setLoading(false)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          enqueueSnackbar('Not logged in.', { variant: 'error' })
          Unauthenticated('/dashboard', navigate)
          return
        }
        enqueueSnackbar('Backend offline.', { variant: 'error' })
        error(err)
        setLoading(false)
      })
  }, [refresh])

  const [open, setOpen] = useState(false)

  return (
    <div className={style.dashboard}>
      <Navbar
        back={() => {
          navigate('/')
        }}
        backIcon={() => <CloseIcon />}
        onRefresh={() => setRefresh(!refresh)}
        title={'Dashboard'}
        auth={auth}
        invitation={invitation}
      />
      {loading ? <LinearProgress color="secondary" /> : null}
      <div>
        <p style={{ userSelect: 'none' }}>Organizations</p>
        <div className={style.orgContainer}>
          <OrganizationCard
            onCreate={() => {
              setOpen(true)
            }}
          />
          {orgs.map((val, index) => {
            return <OrganizationCard key={`dashboard-orgcard-${val._id}`} organization={val} />
          })}
        </div>
      </div>
      <CreateOrganization
        open={open}
        onClose={(created: boolean) => {
          setOpen(false)

          // Refresh organization list if we just created a new one
          if (created) setRefresh(!refresh)
        }}
      />
    </div>
  )
}
